import React, { useState } from "react";
import "./styles.css";
import ReactStars from "react-rating-stars-component";
import { useLocation, useHistory } from "react-router-dom";
import { userContext } from "../../Context/UserContext";
import { toast } from "react-toastify";
import { node_url, url } from "src/Helpers/helpers";
import Reacthtmlparser from 'react-html-parser'
import Geocode from 'react-geocode';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Helmet } from "react-helmet";
Geocode.setApiKey("AIzaSyB23DiBl6meWw9e052iZLdemB6QOZPpn9Q")

const SingleServicePage = () => {
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');
  const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const history = useHistory();
  const [total, setTotal] = React.useState(0);
  const [bookingTime, setBookingTime] = React.useState("");
  const [bookingService, setBookingService] = React.useState("");
  const [bookingServices, setBookingServices] = React.useState("");
  const [otp, setOtp] = React.useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [comment, setComment] = React.useState("");
  const [image, setImage] = React.useState([]);
  const [rating, setRating] = React.useState(0);
  const { user, setLoad } = React.useContext(userContext);
  const [detail, setDetail] = React.useState({});
  const [uniqueCategory, setUniqueCategory] = useState([]);
  const [services, setServices] = React.useState([]);
  const [allServices, setAllServices] = React.useState([]);
  const [hours, setHours] = React.useState([]);
  const [gallery, setGallery] = React.useState([]);
  const [reviews, setReviews] = React.useState([]);
  const [bookmarkStatus, setBookmarkStatus] = React.useState("");
  const [message, setMessage] = React.useState("")
  const [countries, setCountries] = useState([])
  const [phoneCode, setPhoneCode] = useState()
  const [mobile, setMobile] = useState("")
  const [latLng, setLatLng] = useState({
    lat: 0,
    lng: 0
  })
  const [checked, setChecked] = useState(false)
  const [showDownloadStrip, setShowDownloadStrip] = useState(true)

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  const slug_name = useQuery().get('slug');



  React.useEffect(() => {
    async function fetchData() {
      setLoad(true)
      let response = "";

      if (user?.token) {
        response = await fetch(url + "fetchListingDetailUser/" + slug_name, {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        });
      } else {
        response = await fetch(url + "fetchListingDetail/" + slug_name);
      }

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();
        console.log(data)
        if (data.status == 200) {
          setDetail(data?.listing_detail);

          setChecked(data?.listing_detail.first_booking) // if it is not first booking then it will be false 

          Geocode.fromAddress(`${data?.listing_detail?.location_address} , ${data?.listing_detail?.city} , ${data?.listing_detail?.country}`).then(response => {
            const { lat, lng } = response.results[0].geometry.location
            setLatLng({
              lat,
              lng
            })
          })

          setServices(data?.services);
          setUniqueCategory(data?.categories_unique)
          let updated_services = data?.all_services?.map((item) => {
            let condition_price = item?.discount != 0 ? item?.final_amount : item?.price
            return {
              value: item.id,
              label: item.service + " - " + condition_price + " " + data?.listing_detail?.currency,
              service_name: item.service,
              price: item?.price,
              discount: item?.discount,
              final_amount: item?.final_amount
            };
          });

          setBookingServices(updated_services);

          setHours(data?.openingHour);
          setGallery(data?.galleryImages);
          setReviews(data?.reviews);
          setBookmarkStatus(
            data?.listing_detail?.bookmark_status
              ? data?.listing_detail?.bookmark_status
              : "not"
          );
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Oops something went wrong!");
      }
    }

    fetchData().catch((err) => {
      setLoad(false)
      toast.error(err.message);
    });
  }, [slug_name]);

  function ratingChanged(value) {
    setRating(value);
  }

  function addComment() {
    if (rating && comment) {
      async function send() {
        setLoad(true)
        const formData = new FormData();
        formData.append("rating", rating);
        formData.append("comment", comment);
        let images_arr = Object.keys(image).map((key) => {
          return image[key];
        });

        for (let i = 0; i < images_arr.length; i++) {
          formData.append(`image${i}`, images_arr[i], images_arr[i].name);
        }

        const response = await fetch(
          url +
          "create-review/" +
          detail?.id +
          "/" +
          detail?.created_by +
          "/" +
          detail?.business_name,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${user?.token}`,
            },
            body: formData,
          }
        );

        setLoad(false)
        if (response.ok == true) {
          const data = await response.json();

          if (data.status == 200) {
            toast.success(data.message);
          } else {
            toast.error(data.message);
          }
        } else {
          toast.error("Oops something went wrong!");
        }
      }
      send().catch((err) => {
        setLoad(false)
        toast.error(err.message);
      });
    } else {
      toast.error("Please fill rating and comment");
    }
  }

  function AddBookmark() {
    async function send() {
      setLoad(true)
      const response = await fetch(
        url +
        `add-bookmark/${detail?.id}/${bookmarkStatus}/${detail?.created_by}/${detail?.business_name}`,
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          if (bookmarkStatus == "bookmarked") {
            setBookmarkStatus("not");
          } else {
            setBookmarkStatus("bookmarked");
          }
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Oops something went wrong!");
      }
    }

    send().catch((err) => {
      setLoad(false)
      toast.error(err.message);
    });
  }

  function uploadImage(e) {
    if (e.length < 5) {
      setImage(e);
    } else {
      toast.error("Only 4 images are allowed to upload");
    }
  }

  async function sendOtp() {
    setLoad(true)
    const response = await fetch(url + "send-otp-booking/" + detail?.id + '/' + detail?.business_name + '/' + bookingTime + `/${phoneCode.value}${mobile}`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    setLoad(false)
    if (response.ok == true) {
      const data = await response.json();

      if (data.status == 200) {
        setShowOtp(true);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } else {
      toast.error("Oops something went wrong!");
    }
  }

  async function bookService() {

    if (otp && bookingTime && bookingService.length > 0) {
      setLoad(true)
      const formData = new FormData();
      formData.append("otp", otp);
      formData.append("services", JSON.stringify(bookingService));
      formData.append("booking_date_time", bookingTime);
      formData.append("total_amount", total);
      formData.append("mobile_number", `${phoneCode.value}${mobile}`)
      const response = await fetch(
        url + "send-booking-request/" + detail?.id + "/" + detail?.created_by + '/' + detail?.business_name,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
          body: formData,
        }
      );

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          toast.success(data.message);
          history.push("/my-bookings");
        } else {
          toast.error(data?.message);
        }
      } else {
        toast.error("Oops something went wrong!");
      }
    } else {
      toast.error("Please fill otp");
    }
  }

  function doneBooking() {
    if (bookingTime && bookingService.length > 0 && phoneCode.value && mobile) {
      if (showOtp == false) {
        sendOtp().catch((err) => {
          setLoad(false)
          toast.error(err.message);
        });
      } else {
        bookService().catch((err) => {
          setLoad(false)
          toast.error(err.message);
        });
      }
    } else {
      toast.error("Please select booking time, service and fill mobile number with country code");
    }
  }

  function setBookingServiceFunc(value) {
    setBookingService(value);
    let total = 0;

    for (let i = 0; i < value.length; i++) {
      total = parseFloat(total) + parseFloat(value[i].price);
    }

    setTotal(total);
  }

  function sendMessage() {
    if (message) {
      async function send() {
        setLoad(true)
        const formData = new FormData()
        formData.append("message", message)
        formData.append("type", 1)
        formData.append("reciever_id", detail?.user_id)

        const response = await fetch(url + 'send-message', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${user?.token}`
          },
          body: formData
        });

        setLoad(false)
        if (response.ok == true) {
          const data = await response.json();

          if (data.status == 200) {
            toast.success(data.message)
            document.getElementById("message-service-page-popup-close-btn").click();
          } else {
            toast.error(data.message)
          }
        } else {
          toast.error("Oops something went wrong!")
        }
      }

      send().catch(err => {
        setLoad(false)
        toast.error(err.message)
      })
    } else {
      toast.error("Please enter message")
    }
  }

  function videoEmbed(url) {

    let video_id = "";

    let url_split_query = url.split("?")

    if (url_split_query.length > 1) {
      video_id = url_split_query[1].split("=")[1]
    } else {
      video_id = url.split("/")[3]
    }


    return <iframe
      src={`https://www.youtube.com/embed/${video_id}`}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  }

  React.useEffect(() => {
    async function fetchData() {
      setLoad(true)
      const response = await fetch(url + 'grabCountries?order=mobile')

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          let lists = data?.list?.map(item => {
            return {
              value: item,
              label: item
            }
          })

          setCountries(lists)

        } else {
          toast.error(data.message)
        }
      } else {
        toast.error("Oops something went wrong!")
      }
    }

    fetchData().catch(err => {
      setLoad(false)
      toast.error(err.message)
    })
  }, [])

  function onchangeDate(value) {
    setBookingTime(value)
    setLoad(true)
    async function checkAvailable() {
      const response = await fetch(url + 'checkAvailabilityBooking/' + detail?.id + '/' + value, {
        headers: {
          "Authorization": `Bearer ${user?.token}`
        }
      });
      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        console.log(data)
        if (data.status != 200) {
          toast.error(data.message)
        }
      }
    }

    checkAvailable().catch(err => {
      toast.error(err.message)
      setLoad(false)
    })
  }

  function checkFilterUnavailable(date) {
    if (JSON.parse(detail?.date_availability).length > 0) {
      let year = new Date(date).getFullYear();
      let month = new Date(date).getMonth() + 1;
      let day = new Date(date).getDate();
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }

      let current_date_string = `${year}-${month}-${day}`

      let result = JSON.parse(detail?.date_availability).filter(item => {
        if (item == current_date_string) {
          return item
        }
      })

      if (result.length > 0) {

      } else {
        return date
      }
    } else {
      return date
    }
  }

  const [indexImage, setIndexImage] = useState('')

  function openApp() {
    setLoad(true)
    const appUrl = 'madreambody://madreambody.com/ListingDetail/' + slug_name;
    let fallbackUrl = 'https://play.google.com/store/apps/details?id=com.madreambody';

    if (isiOS) {
      fallbackUrl = 'https://apps.apple.com/app/madreambody/id6446048989'
    }

    // Attempt to open the app
    window.location.href = appUrl;

    // Set a timeout to redirect to the fallback URL if the app doesn't open
    setTimeout(() => {
      window.location.href = fallbackUrl;
      setLoad(false)
    }, 2000); // Adjust the timeout duration as needed
  }

  return (
    <>
      <Helmet>
        <title>{`Madreambody - ${detail?.business_name}`}</title>
        <meta property="og:title" content={`Madreambody - ${detail?.business_name}`} />
        <meta property="og:url" content={`${node_url}single_service_page?slug=${slug_name}`} />
        <meta property="og:description" content={`Madreambody - ${detail?.business_name}`} />
        <meta property="og:image" content={`${node_url}${gallery[0]?.url}`} />
        <meta property="og:type" content="article" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
      </Helmet>

      {showDownloadStrip && <div className='app-direction-div justify-content-between align-items-center p-3'>
        <div>
          <h4 className='mb-1'>Madreambody App</h4>
          <p className='mb-0'>Download our free APP for better experience</p>
        </div>

        <div className="d-flex align-items-center">
          <a className="download-Btn" href='javascript:void(0);' onClick={openApp}>Open App</a>
          <a className="close-Btn" href="javascript:void();" onClick={() => setShowDownloadStrip(false)}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      </div>}

      <div className="single-service-page-Y">
        {gallery.length > 0 && (
          <OwlCarousel responsive={{
            0: {
              items: 1,
            },
            300: {
              items: 1,
            },
            500: {
              items: 2,
            },
            780: {
              items: 3,
            },
          }} id="single-page-carousel" className="owl-theme gallery" loop nav>
            {gallery.map((item, index) => (
              <div className="item" style={{ marginRight: 0, cursor: 'pointer' }} onClick={() => {
                setIndexImage(index)
                document.getElementById("image-launch-gallery-modal-btn").click();
              }} key={index}>
                <img
                  className="img-fluid"
                  src={`${node_url}${item.url} `}
                  alt=""
                />
              </div>
            ))}
          </OwlCarousel>
        )}


        <div className="enlarge-img-modal">
          <button id="image-launch-gallery-modal-btn" type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#EnlargeImg">
            Launch demo modal
          </button>

          {gallery.length > 0 && <div class="modal fade" id="EnlargeImg" tabindex="-1" aria-labelledby="EnlargeImgLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content bg-transparent mt-5">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
                <div class="modal-body p-0">
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                      {gallery.map((item, index) => <div class={index == indexImage ? `carousel-item active` : `carousel-item`}>
                        <img src={`${node_url}${item.url} `} class="d-block w-100" alt="..." />
                      </div>)}
                    </div>
                    <button class="carousel-control-prev" type="button" data-target="#carouselExampleControls" data-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="sr-only">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-target="#carouselExampleControls" data-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="sr-only">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        </div>





        <div className="container">
          <div className="row">
            <div className="col-md-8 left-div ">
              <div className="heading-div">
                <h2 className="text-capitalize">
                  {detail?.business_name}
                  <span>
                    <a href="#">{detail?.category_name}</a>
                  </span>
                  <span>
                    <a href="#">{detail?.service_category_name}</a>
                  </span>
                </h2>
                <div className="price-tag mt-3">
                  <i class="fa fa-tag" aria-hidden="true"></i>
                  <span>
                    {detail?.currency} {detail.minimum_booking} - {detail?.currency} {detail.maximum_booking}
                  </span>
                </div>
                <h4>
                  <a href="#">{`${detail?.location_address} , ${detail?.city} , ${detail?.country}`}</a>
                </h4>
                {detail?.reviews > 0 ? (
                  <div className="row">
                    <div className="col-md-5">
                      <div className="star-div d-flex align-self-center justify-content-between">
                        <div className="d-flex">
                          <div className="">
                            <h2 className="star-count">{Math.round(detail?.avg_reviews)}</h2>
                          </div>
                          <div className="ml-2">
                            <ReactStars
                              count={5}
                              value={Math.round(detail?.avg_reviews)}
                              size={30}
                              edit={false}
                              activeColor="#ffd700"
                            />
                          </div>
                        </div>

                        <div className="mt-2 pt-1 mr-4">
                          <p className='raters-count'>({detail?.reviews} review)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ''}

              </div>
              <div className="tabs-content">
                <div className="tabs">
                  <a href="#overview">Overview</a>
                  <a href="#price">Pricing</a>
                  {detail?.youtube_url && <a href="#video">Video</a>}
                  <a href="#location">Location</a>
                  <a href="#all-reviews">Add Review</a>
                </div>

                <div className="tabs-detail">
                  {/* <div id="overview">{Reacthtmlparser(detail?.biography)}</div> */}
                  <div id="overview">
                    <textarea rows={8} className="form-control textarea-to-show" value={detail?.biography?.replace(/(<([^>]+)>)/ig, '')} readOnly={true}></textarea>
                  </div>

                  <div id="price">
                    <h3 className="custom-heading">Pricing</h3>
                    {uniqueCategory.length > 0 && <>
                      {uniqueCategory.map((item1, index1) => (
                        <div key={index1}>
                          <p className="text-center font-weight-bold my-3" style={{ borderBottom: '1px solid black', width: 'fit-content', color: 'red' }}>{item1?.category_name}</p>
                          <ul>
                            {services[index1]?.length > 0 &&
                              services[index1].map((item, index) => (
                                <li key={index}>
                                  <h5>
                                    {item?.service}{" "}
                                    <span className="ml-1">{item?.discount != 0 ? <><s className="text-danger">{item?.price}</s> <span>{item?.final_amount}</span></>: item?.price} {detail?.currency}</span>
                                  </h5>
                                  <p>{item?.description}</p>
                                </li>
                              ))}
                          </ul>
                        </div>
                      ))}
                    </>}

                  </div>

                  {detail?.youtube_url && (
                    <div id="video">
                      <h3 className="custom-heading">Video</h3>
                      {videoEmbed(detail?.youtube_url)}
                    </div>
                  )}


                  <div id="location">
                    <h3 className="custom-heading">Location</h3>
                    <div style={{ height: 400, width: "100%" }}>

                      <iframe src={`https://maps.google.com/maps?q=${latLng.lat}, ${latLng.lng}&z=15&output=embed`} style={{ width: '100%', height: 400 }} frameborder="0"></iframe>

                    </div>
                  </div>

                  <h3 className="total-review-count">Reviews ({detail?.reviews})</h3>
                  <div id="all-reviews">
                    {detail?.reviews > 0 ? (
                      <div className="row">
                        <div className="col-md-3 d-flex flex-column align-items-center total-rating-div justify-content-center">
                          <p className="actual-review-count mb-2">
                            {Math.round(detail?.avg_reviews)}
                          </p>
                          <p className="out-of-5">out of 5.0</p>
                          <ReactStars
                            edit={false}
                            value={Math.round(detail?.avg_reviews)}
                          />
                        </div>
                        <div className="col-md-9 review-scroll-div">
                          {reviews?.length > 0 &&
                            reviews?.map((item, index) => (
                              <div
                                className="d-flex complete-review-div"
                                key={index}
                              >
                                <div className="reviewer-profile-img">
                                  {item?.profile_picture ? (
                                    <img
                                      src={`${node_url}${item?.profile_picture}`}
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src="/assets/images/user.png"
                                      className="img-fluid"
                                    />
                                  )}
                                </div>
                                <div className="reviewer-review">
                                  <h5>{item?.created_by_name}</h5>
                                  <span>{item?.created_date_time}</span>
                                  <ReactStars
                                    edit={false}
                                    value={Math.round(item?.rating)}
                                  />
                                  <p>{item?.comment}</p>
                                  <div className="row">

                                    {item?.image_1 && <div className="col-md-2"><a target="__blank" href={`${node_url}${item?.image_1}`}><img className="img-fluid" src={`${node_url}${item?.image_1}`} /></a></div>}
                                    {item?.image_2 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_2}`}><img className="img-fluid" src={`${node_url}${item?.image_2}`} /></a></div>}
                                    {item?.image_3 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_3}`}><img className="img-fluid" src={`${node_url}${item?.image_3}`} /></a></div>}
                                    {item?.image_4 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_4}`}><img className="img-fluid" src={`${node_url}${item?.image_4}`} /></a></div>}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <div className="my-5">
                        <p className="text-center">No Reviews</p>
                      </div>
                    )}
                  </div>

                  <div id="add-review">
                    {(detail?.can_review == true || user?.role == 3) ? <>
                      <h3 className="custom-heading">Add Review</h3>
                      <p>
                        <a href="">Logged in as {user?.first_name}. </a>
                        Required feilds are marked
                      </p>
                      <div className="row">
                        <div className="col-md-6 justify-content-center mt-2">
                          <div className="d-flex align-items-center">
                            <span>Service</span>
                            <i
                              class="fa fa-question ml-1 mb-1 addReview__icon"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={24}
                            activeColor="#ffd700"
                          />
                        </div>

                        <div className="col-md-6">
                          <input
                            type="file"
                            id="post-comment-image"
                            multiple
                            onChange={(e) => uploadImage(e.target.files)}
                            className="d-none"
                          />
                          <span>You can upload upto 4 images</span>
                          <button
                            type="button"
                            onClick={() =>
                              document.getElementById("post-comment-image").click()
                            }
                            className="custom-button-secondary mt-3 addPhotosButton py-1 d-flex justify-content-center align-items-bottom pt-1"
                          >
                            <i
                              className="fa fa-picture-o "
                              style={{
                                marginTop: "6px",
                              }}
                              aria-hidden="true"
                            ></i>
                            <span className="ml-2 mt-1">Add Photos</span>
                          </button>

                          {image?.length > 0 &&
                            Object.keys(image).map((key) => (
                              <span>{image[key].name}</span>
                            ))}
                        </div>
                      </div>
                      <hr />
                      <label
                        style={{
                          fontWeight: "600",
                          fontSize: "1rem",
                        }}
                      >
                        {"Comment"}
                      </label>
                      <textarea
                        required={true}
                        style={{
                          height: "250px",
                        }}
                        className="form-control p-4"
                        value={comment}
                        placeholder={"Comment"}
                        onChange={(e) => setComment(e.target.value)}
                      ></textarea>

                      <button
                        disabled={user?.token ? false : true}
                        type="button"
                        onClick={() => addComment()}
                        className="custom-button addPhotosButton py-2 mt-5"
                      >
                        Post Comment
                      </button>
                      {user?.token ? (
                        ""
                      ) : (
                        <small className="text-danger mt-2 ml-3" style={{ fontSize: 16 }}>
                          You need to login to perform this action
                        </small>
                      )}
                    </> : <p>Only the booked user can give review</p>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 px-md-4 mb-5 mb-md-0 right-div">
              {(user?.role == 2 || !user?.token) && <>
                <div className="head-div">
                  <h2>Free Booking</h2>
                </div>
                <div className="singleServicePage__customAddReview">
                  <h3 className="custom-heading">
                    <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
                    <span className="ml-2">Booking</span>
                  </h3>
                  <DatePicker showTimeSelect filterDate={checkFilterUnavailable} placeholderText="Select Date & Time" className="form-control singleServicePage__BookingsFields py-4" selected={bookingTime} onChange={(date) => setBookingTime(date)} />
                  {/* <input
                type="datetime-local"
                value={bookingTime}
                onChange={(e) => onchangeDate(e.target.value)}
                className="form-control singleServicePage__BookingsFields py-4"
                readOnly={showOtp}
              /> */}

                  <div class="mt-3 singleServicePage__BookingsFields">
                    <Select
                      isMulti={true}
                      options={bookingServices}
                      value={bookingService}
                      onChange={setBookingServiceFunc}
                      placeholder="Select Service"
                    />
                  </div>

                  {total > 0 && <p className="my-3">Total Amount : {total} {detail?.currency}</p>}

                  <div className="row">
                    <div className="col-md-4 col-3 pr-0">
                      {showOtp ? <input style={{ height: '3rem' }} type="text" className="form-control mt-3" readOnly value={phoneCode.value} /> : <Select placeholder="Code" className="mt-3" options={countries} value={phoneCode} onChange={setPhoneCode} />}

                    </div>
                    <div className="col-md-8 col-9">
                      <input
                        type="number"
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        className="form-control singleServicePage__BookingsFields mt-3"
                        placeholder="Enter mobile number"
                        readOnly={showOtp}
                        style={{ height: '3.2rem' }}
                      />
                    </div>
                  </div>
                  {showOtp && (
                    <div className="form-group mt-3">
                      <input
                        className="form-control otp-input"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        type="text"
                        placeholder="Enter code sent to your mobile"
                      />
                    </div>
                  )}

                  {(user?.token && detail?.first_booking == false) && (
                    <div className="form-group form-check mt-3">
                      <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} className="form-check-input" id="exampleCheck1" />
                      <label className="form-check-label" for="exampleCheck1">your personal data will be used to process your order, support your experience throughout this website and for other purposes described in our &nbsp;<a href="/privacy-policy">Privacy Policy</a></label>
                    </div>
                  )}

                  {detail?.open_status == "open" ? <button
                    onClick={() => doneBooking()}
                    disabled={(user?.token && checked == true) ? false : true}
                    className="custom-button addPhotosButton btn-block py-2 mt-3"
                  >
                    {showOtp == false ? "Verify Booking" : "Book Now"}
                  </button> : <p className="text-center">Vendor is closed now</p>}

                  {user?.token ? (
                    ""
                  ) : (
                    <small className="text-danger ml-3" style={{ fontSize: 16 }}>
                      You need to login for booking
                    </small>
                  )}
                </div>


                <div className="singleServicePage__customAddReview">
                  <div className="d-flex justify-content-between">
                    <div>
                      <span style={{ color: "#dd3333" }}>Live Chat with</span>
                      <h4
                        style={{
                          "&:hover": {
                            color: "#dd3333",
                          },
                        }}
                      >
                        {detail?.username}
                      </h4>
                    </div>

                    <img className="singleServicePage__image" src={detail?.username_profile ? `${node_url}${detail?.username_profile}` : "/assets/images/user.png"} />
                  </div>
                  <hr />
                  <button
                    disabled={user?.token ? false : true}
                    data-toggle="modal"
                    data-target="#messageModal"
                    className="custom-button mt-5 addPhotosButton py-1 d-flex justify-content-center align-items-bottom pt-1"
                  >
                    <i
                      className="fa fa-picture-o "
                      style={{
                        marginTop: "6px",
                      }}
                      aria-hidden="true"
                    ></i>
                    <span className="ml-2 mt-1">Send Message</span>
                  </button>
                  {user?.token ? (
                    ""
                  ) : (
                    <small className="text-danger mt-2" style={{ fontSize: 16 }}>
                      You need to login to perform this action
                    </small>
                  )}
                  <div
                    className="modal fade signin-modal"
                    id="messageModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Send Message
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="message-service-page-popup-close-btn"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <textarea
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            className="form-control"
                            placeholder={`Your message to ${detail?.username}`}
                            style={{
                              height: "150px",
                            }}
                          ></textarea>
                          <button onClick={() => sendMessage()} className=" btn mt-3 particularBooking__dialogButton">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center">
                  <button
                    onClick={() => AddBookmark()}
                    disabled={user?.token ? false : true}
                    className="singleServicePage__transparentButton py-1 d-flex justify-content-center align-items-bottom pt-1"
                  >
                    <i
                      className="fa fa-heart"
                      style={{
                        marginTop: "6px",
                        color: "#dd3333",
                      }}
                      aria-hidden="true"
                    ></i>
                    <span className="ml-2 mt-1">
                      {bookmarkStatus == "bookmarked"
                        ? "Already Bookmarked"
                        : "Bookmark"}
                    </span>
                  </button>
                  {user?.token ? (
                    ""
                  ) : (
                    <small className="mt-2 ml-2 text-danger" style={{ fontSize: 16 }}>
                      You need to login to perform this action
                    </small>
                  )}
                </div>

              </>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleServicePage;
