import React from "react";
const LiveChatListingCard = () => {
  const [isBookmarked, setIsBookmarked] = React.useState(true);
  return (
    <div className="liveChatWith__listingCard row mt-5">
      <div className="col-md-3 p-0">
        <div className="liveChatWith__cardImageDiv">
          <img
            className="liveChatWith__cardImage"
            src="https://www.madreambody.com/wp-content/uploads/2022/05/cbeecc29a52213af38e73e76711c4015-3-462x397.jpeg"
          ></img>
          <span className="liveChatWith__cardImageDivSpan px-2 py-1">
            Botox
          </span>
        </div>
      </div>
      <div className="col-md-7 justify-content-center  d-flex flex-column">
        <div className="ml-5">
          <h5>ahhhh</h5>
          <p>Birmingham, UK</p>
        </div>
      </div>
      <div className="col-md-2 justify-content-center d-flex flex-column">
        <div
          className="justify-content-center d-flex flex-column align-items-center"
          onClick={() => {
            setIsBookmarked(!isBookmarked);
          }}
          style={{
            color: isBookmarked ? "white" : "#C3C3C3",
            backgroundColor: isBookmarked ? "#F3103C" : "#CECECE",
            borderRadius: "30px",
            height: "45px",
            width: "45px",
            cursor: "pointer",
          }}
        >
          <i className="fa fa-heart" aria-hidden="true"></i>
        </div>
      </div>
    </div>
  );
};

function LiveChatWith() {
  return (
    <div>
      <div className="d-flex">
        <img
          className="singleServicePage__image"
          src="https://www.madreambody.com/wp-content/uploads/2021/06/WhatsApp-Image-2021-06-27-at-18.00.38-590x590.jpeg"
          style={{
            height: "100px",
            width: "100px",
          }}
        ></img>
        <h1 className="ml-4 mt-3">Vicky Watson</h1>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="singleServicePage__customAddReview">
            <h3>Contact</h3>
            <hr />
            <p
              style={{
                color: "rgb(163, 163, 163)",
              }}
            >
              Please{" "}
              <a
                style={{
                  color: "#dd3333",
                }}
              >
                Sign In{" "}
              </a>{" "}
              to see contact details
            </p>
            <button
              className="custom-button my-button py-2 px-4 justify-content-between d-flex align-items-center mt-4"
              style={{ width: "120px" }}
              data-toggle="modal"
              data-target="#exampleModal"
            >
              <i className="fa fa-arrow-circle-right"></i>
              Send Message
            </button>
            <div
              className="modal fade signin-modal"
              id="exampleModal"
              tabindex="-1"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Send Message
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <button className=" btn mt-3 particularBooking__dialogButton">
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="singleServicePage__customAddReview">
            <h3>About</h3>
            <hr />
            <p>Test</p>
          </div>
        </div>
        <div className="col-md-8 mt-5">
          <h3 className="mt-3">Vicky's Listing</h3>

          <LiveChatListingCard />
          <LiveChatListingCard />
          <LiveChatListingCard />
          <LiveChatListingCard />
          <LiveChatListingCard />
        </div>
      </div>
    </div>
  );
}

export default LiveChatWith;
