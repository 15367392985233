import React from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { node_url, weeks, url } from "src/Helpers/helpers";
import ReactStars from "react-rating-stars-component";
import OwlCarousel from "react-owl-carousel";
import { userContext } from '../../Context/UserContext'
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Select from "react-select";
import { City } from 'country-state-city';
import Typewriter from 'typewriter-effect';
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next';

const Home = () => {
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');
  const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const { t } = useTranslation()
  const { user, setLoad } = React.useContext(userContext)
  const [listings, setListings] = React.useState([]);
  const [openClosed, setOpenClosed] = React.useState([]);
  const [listingImage, setListingImage] = React.useState([]);
  const [options, setOptions] = React.useState([])
  const [category, setCategory] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [city, setCity] = React.useState("")
  const history = useHistory()
  const [showDownloadStrip, setShowDownloadStrip] = React.useState(true)

  React.useEffect(() => {

    async function fetchData() {
      setLoad(true)
      const response = await fetch(url + 'grabAllCategoriesSubCategories');
      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();
        if (data.status == 200) {
          setOptions(data?.category_list?.map((item) => {
            return {
              value: item?.id,
              label: item?.name,
              url: item?.url,
              bg: item?.bg,
              icon: item?.icon
            }
          }))
        } else {
          toast.error(data.message)
        }
      } else {
        toast.error("Internal Server Error")
      }
    }

    fetchData().catch(err => {
      setLoad(false)
      toast.error(err.message)
    })
  }, []);

  React.useEffect(() => {
    async function fetchCitiesFromListings() {
      setLoad(true)

      const response = await fetch(url + 'getAllCitiesBasedOnListings')

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          let result = []

          data?.list?.map((item) => {
            result.push({
              value: item.city,
              label: item.city
            })
          })

          setCities(result)
        } else {
          toast.error(data?.message)
        }

      } else {
        toast.error("Oops something went wrong!")
      }

    }

    fetchCitiesFromListings().catch(err => {
      setLoad(false)
      toast.error(err?.message)
    })
  }, [])


  React.useEffect(() => {
    async function getData() {
      setLoad(true)
      let day = new Date().getDay();
      day = weeks[day - 1];
      // const response = await fetch(url + "allListings/" + day);
      const response = await fetch(url + `fetchSearchPageListing?page=1&category=All&condition=Default Order&version=new`);
      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();
        console.log(data)
        if (data.status == 200) {
          setListings(data.list);
          setOpenClosed(data.open_status);
          // setListingImage(data.listing_image);
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Oops something went wrong");
      }
    }

    getData().catch((err) => {
      setLoad(false)
      toast.error(err.message);
    });
  }, []);

  function handleSearch() {

    let redirect_url = "/particular-serach-page"

    if (category.value) {
      redirect_url = redirect_url + `?category=${category.value}`
    } else {
      redirect_url = redirect_url + `?category=All`
    }

    if (Object.keys(city).length > 0) {
      redirect_url = redirect_url + `&city=${city.value}`
    }

    history.push(redirect_url)
  }

  function openApp() {
    setLoad(true)
    const appUrl = 'madreambody://madreambody.com';
    let fallbackUrl = 'https://play.google.com/store/apps/details?id=com.madreambody';

    if (isiOS) {
      fallbackUrl = 'https://apps.apple.com/app/madreambody/id6446048989'
    }

    // Attempt to open the app
    window.location.href = appUrl;

    // Set a timeout to redirect to the fallback URL if the app doesn't open
    setTimeout(() => {
      window.location.href = fallbackUrl;
      setLoad(false)
    }, 3000); // Adjust the timeout duration as needed
  }


  return (
    <>

      {showDownloadStrip && <div className='app-direction-div justify-content-between align-items-center p-3'>
        <div>
          <h4 className='mb-1'>Madreambody App</h4>
          <p className='mb-0'>Download our free APP for better experience.</p>
        </div>

        <div className="d-flex align-items-center">
          <a className="download-Btn" href='javascript:void();' onClick={openApp}>Open App</a>
          <a className="close-Btn" href="javascript:void();" onClick={() => setShowDownloadStrip(false)}>
            <i class="fa fa-times" aria-hidden="true"></i>
          </a>
        </div>
      </div>}

      <div className="home-page-div">
        {/* banner section */}
        <div className="banner-div">
          <img className="d-block d-md-none banner-img" src="/assets/images/mobile-banner-top.png" alt="" />
          <video className="back-video d-none d-md-block" autoPlay loop muted playsInline disablePictureInPicture={true}>
            <source src="/assets/Madream-body-New-3-1.m4v" />
          </video>
          <div className="inner-content container">
            <h2>
              Looking for a Professional
              {/* <span className="Typewriter">-</span> {" "}
              <Typewriter
                options={{
                  strings: options.map((item) => item.label),
                  autoStart: true,
                  loop: true,
                }}
              /> */}
              {/* <a
                href=""
                class="typewrite"
                data-period="2000"
                data-type='[ "Hi, Im Si.", "I am Creative.", "I Love Design.", "I Love to Develop." ]'
              >
                <span class="wrap"></span>
              </a> */}
            </h2>

            <p>
              {t('Recommended Beauty, Cosmetic & Personal Care Professionals near you!')}
            </p>
            <div className="custom-serach-div">
              {/* <div className="search-inputs-bordered">
                <label htmlFor="">Which Services You Are Looking For?</label>
              </div> */}

              <div className="search-inputs">
                <Select
                  options={options}
                  value={category}
                  onChange={setCategory}
                  placeholder={t('Select Service')}
                />
              </div>


              <div className="search-inputs">
                <Select
                  placeholder={t('Select City')} options={cities} value={city} onChange={setCity}
                />
              </div>

              <a href="javascript:void(0)" onClick={() => handleSearch()}>{t('Search')}</a>
              {/* <Select options={options} /> */}
            </div>
          </div>
        </div>

        {/* Worldwide Recommended Professionals section */}
        <div className="WW-recommend-div container">
          <h3 className="heading">
            {t('Worldwide Recommended Professionals')}
            <span>{t('Offers Off Up To 50% Available')}</span>
          </h3>

          <OwlCarousel responsive={{
            0: {
              items: 1,
            },
            500: {
              items: 2,
            },
            780: {
              items: 3,
            },
            1000: {
              items: 3,
            },
          }} autoplayHoverPause={true} className="owl-theme custom-nav-btns" loop margin={10} dots={false} items={3}
             nav>
            {(listings.length > 0) &&
              listings.map((item, index) => (
                <div key={index}>
                  <a
                    className="carousel-card-anchor text-decoration-none"
                    href={`single_service_page?slug=${item?.slug_name}`}
                  >
                    <div
                      className="carousel-card">
                      <img style={{ height: '100%', objectFit: 'cover', }} src={`${node_url}${item?.image_url}`} alt="" />
                      <div className="booking-status">
                        <div className="price-tag">
                          <i class="fa fa-tag" aria-hidden="true"></i>
                          <span>
                            {item?.currency} {item.minimum_booking} - {item?.currency} {item.maximum_booking}
                          </span>
                        </div>
                      </div>
                      {/* {openClosed[index] == "open" ? (
                        <div className="availability-status open">Now Open</div>
                      ) : (
                        <div className="availability-status closed">
                          Now Closed
                        </div>
                      )} */}
                      {item?.max_discount > 0 && <div style={{position : 'absolute',top : 10,right :10,backgroundColor : 'red',color : 'white',padding : '5px 25px',borderRadius : 50,display : 'flex',justifyContent : 'center',alignItems : 'center',flexDirection : 'column'}}>
                        <p className="mb-0 mt-2 font-weight-bold">{item?.max_discount}%</p>
                        <p className="mt-0 font-weight-bold">OFF</p>
                      </div>}
                      <div className="inner-content">
                        <span className="tag-span">{item?.category_name}</span>
                        <h3>{item?.business_name}</h3>
                        <span className="address-div">{`${item?.city} ${item?.country}`}</span>
                      </div>
                    </div>
                    <div className="review-div">
                      {item?.reviews > 0 ? (
                        <div>
                          <div className="d-flex align-items-center">
                            <ReactStars
                              count={5}
                              value={Math.round(item?.avg_reviews)}
                              size={24}
                              activeColor="#ffd700"
                            />

                            <p className="mb-0 ml-2" style={{ fontSize: 18 }}>
                              ({item?.reviews} Reviews)
                            </p>
                          </div>
                        </div>
                      ) : (
                        <span>No reviews yet</span>
                      )}
                    </div>
                  </a>
                </div>
              ))}
          </OwlCarousel>
        </div>

        {/* wellbeing services full section */}
        {options.length > 0 && <div className="pt-5 pb-5">
          <div className="container">
            {/* popular beauty section */}
            <div>
              <h3 className="heading mt-3">
                {t('Popular Beauty & Wellbeing Services')}
                <span></span>
              </h3>
              <div className="wellbeing-service-cards">
                <OwlCarousel responsive={{
                  0: {
                    items: 1,
                  },
                  300: {
                    items: 2,
                  },
                  500: {
                    items: 3,
                  },
                  780: {
                    items: 4,
                  },
                  1000: {
                    items: 5,
                  },
                }} autoplayHoverPause={true} className="owl-theme custom-nav-btns" loop margin={30} items={5} dots={false} autoplay autoplayTimeout={2000} nav>
                  {options.map((item, index) => (
                    <a style={{
                      backgroundImage: `url("${node_url}${item?.bg}")`
                    }} key={index} href={`/particular-serach-page?category=${item?.value}`}>
                      <div style={{ position: "relative", zIndex: 2, minHeight: 120, maxHeight: 120 }}>
                        <img src={`${node_url}${item?.icon}`} alt="" />
                        <p>{item.label}</p>
                      </div>
                    </a>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>}

        {/* Find professional section */}
        <div className="find-professional-banner">
          <img className="d-block d-md-none banner-img" src="/assets/images/mobile-banner-bottom.jpeg" alt="" />
          <video className="back-video d-none d-md-block" autoPlay loop muted playsInline disablePictureInPicture={true}>
            <source src="https://madreambody.com/uploads/find-prof.mp4" />
          </video>
          <div className="container">
            <h3>
              {t('Ready to transform your body and make that ever lasting change .')}
            </h3>
            <p>
              {t('Verified worldwide professionals awaiting your request to a new you, book your Appointment with them today')}
            </p>
            <a href={`/particular-serach-page?category=All`}>{t('Find a professional')}</a>
          </div>
        </div>

      </div>
    </>
  );
};

export default Home;
