import React from "react";
function CustomInput({ value, setvalue, label, textArea, required, fontLight }) {
  return (
    <div>
      <label
        style={{
          fontWeight: !fontLight ? "600":"400",
          fontSize: !fontLight ? "0.8rem":"0.9rem",
          color: !fontLight ? "black":"rgb(168, 168, 168)",
        }}
      >
        {label}{required && <small><i className="fa fa-asterisk ml-1 text-danger"></i></small>}
      </label>
      {textArea ? (
        <textarea
          required={true}
          style={{
            height: "250px",
          }}
          className="form-control p-4"
          value={value}
          placeholder={label}
          onChange={(e) => setvalue(e.target.value)}
        ></textarea>
      ) : (
          <input
            required={required}
            className="form-control p-4"
            value={value}
            placeholder={label}
            onChange={(e) => setvalue(e.target.value)}
          ></input>
      )}
    </div>
  );
}

export default CustomInput;
