import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { node_url, url } from '../Helpers/helpers';
import { userContext } from '../Context/UserContext'
import { Country } from 'country-state-city';
import Select from 'react-select'
import i18next, { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Autosuggest from 'react-autosuggest';

export default function Header({ custom_class }) {
    const { t } = useTranslation()
    const history = useHistory()
    const [show, setShow] = useState(false);
    const [whiteNav, SetWhiteNav] = useState(false)
    const { user, setUser, setLoad } = React.useContext(userContext)
    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [gender, setGender] = useState("")
    const [country, setCountry] = useState()
    const [cities, setCities] = React.useState([])
    const [city, setCity] = React.useState("")
    const [password, setPassword] = useState("")
    const [countries, setCountries] = React.useState([])
    const [options, setOptions] = useState([])
    const [showOtp, setShowOtp] = useState(false)
    const [otp, setOtp] = useState("");
    const [checked, setChecked] = useState(false)
    const [showMobile, setShowMobile] = useState(false)
    const [citySuggestion, setCitySuggestion] = useState([])
    const [refferal, setRefferal] = useState("")

    React.useEffect(() => {
        async function fetchDataCountry() {
            setLoad(true)
            const response = await fetch(url + 'grabCountries')

            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();

                if (data.status == 200) {
                    let lists = data?.list?.map(item => {
                        return {
                            value: item?.id,
                            label: item?.name,
                            currency: item?.currency,
                            phoneCode: item?.phoneCode
                        }
                    })

                    setCountries(lists)
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Oops something went wrong!")
            }
        }

        fetchDataCountry().catch(err => {
            setLoad(false)
            toast.error(err.message)
        })


        async function fetchData() {
            setLoad(true)
            const response = await fetch(url + 'grabAllCategoriesSubCategories');
            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();
                if (data.status == 200) {
                    setOptions(data?.category_list?.map((item) => {
                        return {
                            value: item?.id,
                            label: item?.name,
                            url: item?.url
                        }
                    }))
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Internal Server Error")
            }
        }

        fetchData().catch(err => {
            setLoad(false)
            toast.error(err.message)
        })
    }, [])

    useEffect(() => {
        if (window.screen.width < 1201) {
            setShow(true)
        } else {
            setShow(false)
        }
    }, [])

    function logout() {
        setLoad(true);
        setUser(null);
        setLoad(false);
        localStorage.setItem('madreambody_user', null);
    }


    useEffect(() => {
        if (custom_class == "fixed-top") {
            const onScroll = () => {
                if (window.scrollY > 230) {
                    if (whiteNav != true) {
                        SetWhiteNav(true)
                    }
                } else {
                    SetWhiteNav(false)
                }
            }
            window.addEventListener("scroll", onScroll, { passive: true });
            return () => window.removeEventListener('scroll', onScroll);
        }
    }, [])

    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData()
        formData.append("email", email)
        formData.append("password", password)
        formData.append("gender", gender)
        formData.append("role", 2)
        formData.append("first_name", fname)
        formData.append("last_name", lname)
        formData.append("country", JSON.stringify(country))
        formData.append("city", city)
        formData.append("mobile", mobile.split("-")[1])
        formData.append("invited_by", refferal)

        async function submit() {
            setLoad(true)
            const response = await fetch(url + 'signup', {
                method: 'POST',
                body: formData
            });

            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();

                if (data.status == 200) {
                    setUser(data.user_data)
                    setEmail("")
                    setPassword("")
                    setGender("")
                    setFname("")
                    setLname("")
                    setCountry("")
                    setMobile("")
                    document.getElementById("signin_modal_close_btn").click();
                    history.push('/dashboard')
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Internal Server Error")
            }
        }

        if (Object.keys(country).length > 0) {
            submit().catch(err => {
                setLoad(false)
                toast.error(err.message)
            })
        } else {
            toast.error("Please select country")
        }
    }

    function login(e) {
        e.preventDefault();
        async function submit() {
            setLoad(true)
            const formData = new FormData()
            formData.append("email", email)
            formData.append("password", password)
            const response = await fetch(url + 'login', {
                method: 'POST',
                body: formData
            });

            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();
                if (data.status == 200) {
                    setUser(data.user_data)
                    setEmail("")
                    setPassword("")
                    document.getElementById("signin_modal_close_btn").click();
                    history.push('/dashboard')
                } else if (data.status == 201) {
                    setShowMobile(true)
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Internal Server Error")
            }
        }

        submit().catch(err => {
            setLoad(false)
            toast.error(err.message)
        })
    }

    function verifyVendor() {
        async function send() {
            setLoad(true)
            const formData = new FormData();
            formData.append("otp", otp)
            formData.append("email", email)

            const response = await fetch(url + 'verify-vendor', {
                method: 'POST',
                body: formData
            })

            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();

                if (data.status == 200) {
                    setUser(data.user_data);
                    setEmail("");
                    setPassword("");
                    setGender("");
                    setFname("");
                    setLname("");
                    setCountry("");
                    setMobile("");
                    setOtp('')
                    setShowOtp(false)
                    document.getElementById("signin_modal_close_btn").click();
                    history.push('/dashboard')
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Oops something went wrong!")
            }
        }

        if (otp) {
            send().catch(err => {
                setLoad(false)
                toast.error(err.message)
            })
        } else {
            toast.error("Please enter otp")
        }
    }

    function forgotPassword(e) {
        setLoad(true)
        e.preventDefault();
        async function submit() {
            const response = await fetch(url + 'forgotPasswordReset/' + email)

            setLoad(false)
            if (response.ok == true) {
                const data = await response.json();
                if (data.status == 200) {
                    toast.success(data.message)
                } else {
                    toast.error(data.message)
                }
            } else {
                toast.error("Oops something went wrong!")
            }
        }

        submit().catch(err => {
            setLoad(false)
            toast.error(err.message)
        })
    }

    function updateMobileNumber() {
        if (email && password && Object.keys(country).length > 0 && mobile) {
            async function submit() {
                setLoad(true)
                const formData = new FormData();
                formData.append("email", email)
                formData.append("password", password)
                formData.append("country", JSON.stringify(country))
                formData.append("mobile", mobile.split("-")[1])

                const response = await fetch(url + 'update-mobile-number-login', {
                    method: 'POST',
                    body: formData
                });

                setLoad(false)
                if (response.ok === true) {
                    const data = await response.json();

                    if (data.status == 200) {
                        setShowOtp(true)
                    } else {
                        toast.error(data.message)
                    }
                } else {
                    toast.error("Oops something went wrong!")
                }
            }

            submit().catch(err => {
                toast.error(err.message)
                setLoad(false)
            })
        } else {
            toast.error("Please fill all data")
        }
    }

    async function fetchCountriesCities() {
        const response = await fetch(url + 'getCityByState/' + country.value)
        setLoad(false)
        if (response.ok == true) {
            const data = await response.json();

            if (data.status == 200) {
                let lists = data?.list?.map(item => {
                    return {
                        value: item?.id,
                        label: item?.name
                    }
                })

                setCities(lists)
            } else {
                toast.error(data.message)
            }

        } else {
            toast.error("Oops something went wrong!")
        }
    }

    useEffect(() => {
        if (country && country.value) {
            fetchCountriesCities().catch(err => {
                toast.error(err.message)
                setLoad(false)
            })
        }
    }, [country])

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : cities.filter(lang =>
            lang.label.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    // input value for every given suggestion.
    const getSuggestionValue = suggestion => suggestion.label;

    // Use your imagination to render suggestions.
    const renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );


    const onSuggestionsFetchRequested = ({ value }) => {
        setCitySuggestion(getSuggestions(value))
    };


    const onSuggestionsClearRequested = () => {
        setCitySuggestion([])
    };

    function onChange(event, { newValue }) {
        setCity(newValue)
    }


    const inputProps = {
        placeholder: t('Type city'),
        className: 'form-control',
        value: city,
        onChange: onChange
    };

    return (
        <>
            <div className='navbar-div'>
                <nav className={whiteNav ? `${custom_class} navbar navbar-expand-xl active` : `${custom_class} navbar navbar-expand-xl`}>
                    <a className="navbar-brand" href="/">
                        <img className='img-fluid' src="/assets/images/logo.png" alt="" />
                    </a>

                    <div className='d-flex align-items-center'>
                        {/* <div class="dropdown lang-mob-btn d-block d-xl-none">
                        <a href='javascript:void(0)' onClick={() => setShow(!show)} class="dropdown-toggle text-decoration-none" type="button" id="dropdownMenu1" data-toggle={show ? 'dropdown' : ""} aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-language mr-1"></i>
                            {i18next.language == "en" && 'En'}
                            {i18next.language == "ar" && 'Ar'}
                            {i18next.language == "fr" && 'Fr'}
                            {i18next.language == "sp" && 'Sp'}
                            {i18next.language == "hi" && 'Hi'}
                            {i18next.language == "ben" && 'Ben'}
                            {i18next.language == "chi" && 'Chi'}
                            {i18next.language == "ger" && 'Ger'}
                            {i18next.language == "it" && 'It'}
                            {i18next.language == "rus" && 'Rus'}
                        </a>
                        <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu1">
                            <li onClick={() => changeLanguage("en")} class="dropdown-item"><a href="javascript:void(0)">English</a></li>
                            <li onClick={() => changeLanguage("ar")} class="dropdown-item"><a href="javascript:void(0)">Arabic</a></li>
                            <li onClick={() => changeLanguage("fr")} class="dropdown-item"><a href="javascript:void(0)">French</a></li>
                            <li onClick={() => changeLanguage("sp")} class="dropdown-item"><a href="javascript:void(0)">Spanish</a></li>
                            <li onClick={() => changeLanguage("hi")} class="dropdown-item"><a href="javascript:void(0)">Hindi </a></li>
                            <li onClick={() => changeLanguage("ben")} class="dropdown-item"><a href="javascript:void(0)">Bengali </a></li>
                            <li onClick={() => changeLanguage("chi")} class="dropdown-item"><a href="javascript:void(0)">Chinese</a></li>
                            <li onClick={() => changeLanguage("ger")} class="dropdown-item"><a href="javascript:void(0)">German</a></li>
                            <li onClick={() => changeLanguage("it")} class="dropdown-item"><a href="javascript:void(0)">Italian </a></li>
                            <li onClick={() => changeLanguage("rus")} class="dropdown-item"><a href="javascript:void(0)">Russian </a></li>
                        </ul>
                    </div> */}

                        {/* {window.innerWidth < 1200 && <div className='d-flex d-xl-none align-items-center google-language-div'>
                        <i class="fa fa-language mr-2"></i>
                        <div id="google_element"></div>
                    </div>} */}

                        <button onClick={() => {
                            if (whiteNav == false) {
                                SetWhiteNav(true)
                            }
                        }} className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarsExample06" aria-controls="navbarsExample06" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">
                                <i class="fa fa-bars" style={{ color: 'red' }} aria-hidden="true"></i>
                            </span>
                        </button>
                    </div>

                    <div className="navbar-collapse collapse" id="navbarsExample06">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active">
                                <a className="nav-a" href="/">
                                    {t('Home')}
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-a" href="/about">{t('About Us')}</a>
                            </li>

                            <li className='nav-item'>
                                {/* <div className='d-flex justify-content-between'>

                                <div class="dropdown">
                                    <a href='javascript:void(0)' onClick={() => setShow(!show)} class="dropdown-toggle text-decoration-none" type="button" id="dropdownMenu1" data-toggle={show ? 'dropdown' : ""} aria-haspopup="true" aria-expanded="false">
                                        {t('Services')}
                                    </a>
                                    <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu1">
                                        {options.length > 0 && options.map((item, index_value) => <li key={index_value} class="dropdown-item"><a href={`/particular-serach-page?category=${item?.value}`}>{item?.label}</a></li>)}
                                    </ul>
                                </div>
                                <div className='d-xl-flex d-none align-items-center google-language-div'>
                                    <i class="fa fa-language mr-2"></i>
                                    <div id="google_element"></div>
                                </div> */}
                                {/* <div class="dropdown d-none d-xl-block">
                                    <a href='javascript:void(0)' onClick={() => setShow(!show)} class="dropdown-toggle text-decoration-none" type="button" id="dropdownMenu1" data-toggle={show ? 'dropdown' : ""} aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-language mr-2"></i>
                                        {i18next.language == "en" && 'English'}
                                        {i18next.language == "ar" && 'Arabic'}
                                        {i18next.language == "fr" && 'French'}
                                        {i18next.language == "sp" && 'Spanish'}
                                        {i18next.language == "hi" && 'Hindi'}
                                        {i18next.language == "ben" && 'Bengali'}
                                        {i18next.language == "chi" && 'Chinese'}
                                        {i18next.language == "ger" && 'German'}
                                        {i18next.language == "it" && 'Italian'}
                                        {i18next.language == "rus" && 'Russian'}
                                    </a>
                                    <ul class="dropdown-menu multi-level" role="menu" aria-labelledby="dropdownMenu1">
                                        <li onClick={() => changeLanguage("en")} class="dropdown-item"><a href="javascript:void(0)">English</a></li>
                                        <li onClick={() => changeLanguage("ar")} class="dropdown-item"><a href="javascript:void(0)">Arabic</a></li>
                                        <li onClick={() => changeLanguage("fr")} class="dropdown-item"><a href="javascript:void(0)">French</a></li>
                                        <li onClick={() => changeLanguage("sp")} class="dropdown-item"><a href="javascript:void(0)">Spanish</a></li>
                                        <li onClick={() => changeLanguage("hi")} class="dropdown-item"><a href="javascript:void(0)">Hindi </a></li>
                                        <li onClick={() => changeLanguage("ben")} class="dropdown-item"><a href="javascript:void(0)">Bengali </a></li>
                                        <li onClick={() => changeLanguage("chi")} class="dropdown-item"><a href="javascript:void(0)">Chinese</a></li>
                                        <li onClick={() => changeLanguage("ger")} class="dropdown-item"><a href="javascript:void(0)">German</a></li>
                                        <li onClick={() => changeLanguage("it")} class="dropdown-item"><a href="javascript:void(0)">Italian </a></li>
                                        <li onClick={() => changeLanguage("rus")} class="dropdown-item"><a href="javascript:void(0)">Russian </a></li>
                                    </ul>
                                </div> */}

                                <div className='d-xl-none d-block'>

                                    {user ? <div className='d-flex align-items-center'>
                                        <div>
                                            {/* <p className='mb-0 text-white font-weight-bold FS-16'>
                                                Hello! <span className='text-uppercase'> {user?.first_name}</span>
                                            </p> */}
                                        </div>
                                        <div className='profile-photo-div position-relative'>
                                            {user?.profile_picture ? (
                                                <img
                                                    className='img-fluid dropdown-toggle'
                                                    type='button'
                                                    id='dropdownMenuButton'
                                                    data-toggle='dropdown'
                                                    aria-expanded='false'
                                                    src={`${node_url}${user?.profile_picture}`}
                                                    alt=''
                                                />
                                            ) : (
                                                <p
                                                    className='dropdown-toggle text-uppercase'
                                                    type='button'
                                                    id='dropdownMenuButton'
                                                    data-toggle='dropdown'
                                                    aria-expanded='false'>
                                                    {user?.first_name[0]}
                                                </p>
                                            )}
                                            <div class='dropdown-menu' aria-labelledby='dropdownMenuButton'>
                                                <a class='dropdown-item' href={(user?.role == 1 || user?.role == 3) ? '/dashboard' : '/my-profile'}>
                                                    <i class='fa fa-info-circle mr-2' aria-hidden='true'></i> {(user?.role == 1 || user?.role == 3) ? 'Dashboard' : 'Profile Information'}
                                                </a>
                                                <div class='dropdown-divider'></div>
                                                <a
                                                    class='dropdown-item'
                                                    href='javascript:void(0)'
                                                    onClick={() => logout()}>
                                                    <i class='fa fa-sign-out mr-2' aria-hidden='true'></i>Log Out
                                                </a>
                                            </div>
                                        </div>
                                    </div> : <div className='login-btns'>
                                        <a id='login-modal-opener-a' className='mr-1' href="#" data-toggle="modal" data-target="#exampleModal">
                                            <i class="fa fa-sign-in" aria-hidden="true"></i> {t('Sign In')}
                                        </a>
                                    </div>}
                                </div>

                                {/* </div> */}
                            </li>



                            {/* <li className="nav-item dropdown">
                            <a className="nav-a dropdown-toggle" href="#" id="dropdown03" data-toggle="dropdown" aria-expanded="false">Beauty Care</a>
                            <div className="dropdown-menu" aria-labelledby="dropdown03">
                                <a className="dropdown-item" href="#">Hair-Nail-Fashion-Wax</a>
                                <a className="dropdown-item" href="#">Eyebrow-Eyelash</a>
                                <a className="dropdown-item" href="#">Makeup-Tattoo</a>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-a dropdown-toggle" href="#" id="dropdown03" data-toggle="dropdown" aria-expanded="false">Find A Professional</a>
                            <div className="dropdown-menu" aria-labelledby="dropdown03">
                                <a className="dropdown-item" href="#">Search On Map</a>
                                <a className="dropdown-item" href="#">Search By Services</a>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-a" href="#">Our Blog</a>
                        </li> */}
                        </ul>
                        <div className='d-none d-xl-block'>
                            {user ? <div className='d-flex align-items-center'>
                                <div>
                                    {/* <p className='mb-0 font-weight-bold FS-16 user-name'>
                                    Hello! <span className='text-uppercase'> {user?.first_name}</span>
                                </p> */}
                                </div>
                                <div className='profile-photo-div position-relative'>
                                    {user?.profile_picture ? (
                                        <img
                                            className='img-fluid dropdown-toggle'
                                            type='button'
                                            id='dropdownMenuButton'
                                            data-toggle='dropdown'
                                            aria-expanded='false'
                                            src={`${node_url}${user?.profile_picture}`}
                                            alt=''
                                        />
                                    ) : (
                                        <p
                                            className='dropdown-toggle text-uppercase'
                                            type='button'
                                            id='dropdownMenuButton'
                                            data-toggle='dropdown'
                                            aria-expanded='false'>
                                            {user?.first_name[0]}
                                        </p>
                                    )}
                                    <div class='dropdown-menu position-absolute' aria-labelledby='dropdownMenuButton'>
                                        <a class='dropdown-item' href={(user?.role == 1 || user?.role == 3) ? '/dashboard' : '/my-profile'}>
                                            <i class='fa fa-info-circle mr-2' aria-hidden='true'></i> {(user?.role == 1 || user?.role == 3) ? 'Dashboard' : 'Profile Information'}
                                        </a>
                                        <div class='dropdown-divider'></div>
                                        <a
                                            class='dropdown-item'
                                            href='javascript:void(0)'
                                            onClick={() => logout()}>
                                            <i class='fa fa-sign-out mr-2' aria-hidden='true'></i>Log Out
                                        </a>
                                    </div>
                                </div>
                            </div> : window.location.origin == 'https://testmadreambody.com' ? <div className='login-btns'>
                                <a className='mr-1' href="#" data-toggle="modal" data-target="#exampleModal1">
                                    <i class="fa fa-sign-in" aria-hidden="true"></i> {t('Sign In')}
                                </a>

                                {/* <a href="/register_business#register-business-form">
                            <i class="fa fa-user-plus" aria-hidden="true"></i> Register Your Business
                        </a> */}
                            </div> : <></>}
                        </div>
                    </div>
                </nav >


                {/* signin modal */}

                <div className="modal fade signin-modal" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{t('Sign In')}</h5>
                                <button id='signin_modal_close_btn' type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <ul className="nav nav-tabs" id="myTab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-a active" id="logIn-tab" data-toggle="tab" href="#logIn" role="tab" aria-controls="logIn" aria-selected="true">{t('Log In')}</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-a" id="newCustomer-tab" data-toggle="tab" href="#newCustomer" role="tab" aria-controls="newCustomer" aria-selected="false">{t("New Customer")}</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-a" href="/register_business#register-business-form">{t("New Vendor")}</a>
                                    </li>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade show active" id="logIn" role="tabpanel" aria-labelledby="logIn-tab">
                                        <div className='mt-4 pt-2'>
                                            <form onSubmit={e => login(e)}>
                                                <div className='position-relative'>
                                                    <input value={email} readOnly={showOtp} onChange={e => setEmail(e.target.value)} required className="form-control" type="email" placeholder={t("Email")} />
                                                    <i className="fa fa-user-o" aria-hidden="true"></i>
                                                </div>

                                                <div className='position-relative'>
                                                    <input className="form-control" readOnly={showOtp} type="password" value={password} onChange={e => setPassword(e.target.value)} required placeholder={t("Password")} />
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </div>

                                                {showMobile && <>
                                                    <div className='position-relative'>
                                                        <div id='register_customer_popup_country_select'>
                                                            <Select className='country-select-signin' placeholder={t('Select Country')} options={countries} value={country} onChange={(value) => {
                                                                setCountry(value)
                                                                setMobile(`+${value.phoneCode}-`)
                                                            }} />
                                                            <i className="fa fa-globe" aria-hidden="true"></i>
                                                        </div>
                                                    </div>

                                                    <div className='position-relative'>
                                                        <input required className="form-control" type="tel" value={mobile} onChange={e => setMobile(e.target.value)} placeholder={t('Enter mobile Number without country code')} />
                                                        <i className="fa fa-phone" aria-hidden="true"></i>
                                                    </div>

                                                </>}

                                                {showOtp && <div className='position-relative'>
                                                    <input className="form-control" type="number" value={otp} onChange={e => setOtp(e.target.value)} required placeholder="Enter OTP for validation" />
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </div>}

                                                <a href="javascript:void(0);" onClick={() => {
                                                    document.getElementById("signin_modal_close_btn").click()
                                                    document.getElementById("lost-password-anchor").click()

                                                }}>{t("Lost Your Password?")}</a>
                                                <a className='d-none' id='lost-password-anchor' data-toggle="modal" data-target="#lostPasswordModal">{t("Lost Your Password?")}</a>

                                                {showOtp ? <button className='btn' type='button' onClick={() => verifyVendor()}>{t('Verify OTP')}</button> : showMobile ? <button className='btn' type='button' onClick={() => updateMobileNumber()}>{t('Send OTP')}</button> : <button className='btn' type='submit'>{t('Login')}</button>}
                                            </form>
                                        </div>
                                    </div>

                                    <div className="tab-pane fade" id="newCustomer" role="tabpanel" aria-labelledby="newCustomer-tab">
                                        <div className='mt-4 pt-2'>
                                            <a className='customer-btn' href="javascript:void(0)"><i className="fa fa-user-o" aria-hidden="true"></i> {t('Registeration')}</a>

                                            <form onSubmit={e => handleSubmit(e)}>
                                                <div className='position-relative mt-4'>
                                                    <input required value={fname} onChange={e => setFname(e.target.value)} className="form-control" type="text" placeholder={t('First Name')} />
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </div>

                                                <div className='position-relative'>
                                                    <input required value={lname} onChange={e => setLname(e.target.value)} className="form-control" type="text" placeholder={t('Last Name')} />
                                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                                </div>

                                                <div className='position-relative'>
                                                    <div id='register_customer_popup_country_select'>
                                                        <Select className='country-select-signin' placeholder={t('Select Country')} options={countries} value={country} onChange={(value) => {
                                                            setCountry(value)
                                                            setMobile(`+${value.phoneCode}-`)
                                                        }} />
                                                        <i className="fa fa-globe" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="position-relative">
                                                    {/* <Select options={cities} onChange={setCity} /> */}
                                                    <Autosuggest
                                                        suggestions={citySuggestion}
                                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                        getSuggestionValue={getSuggestionValue}
                                                        renderSuggestion={renderSuggestion}
                                                        inputProps={inputProps}
                                                        containerProps={{
                                                            className: 'city-input-autoSuggest'
                                                        }}
                                                    />
                                                    <i className="fa fa-map-marker" aria-hidden="true"></i>

                                                </div>

                                                <div className='position-relative'>
                                                    <input required className="form-control" type="tel" value={mobile} onChange={e => setMobile(e.target.value)} placeholder={t('Enter mobile Number without country code')} />
                                                    <i className="fa fa-phone" aria-hidden="true"></i>
                                                </div>

                                                <div className='position-relative'>
                                                    <select required className="form-control" value={gender} onChange={e => setGender(e.target.value)}>
                                                        <option selected>{t('Select Gender')}</option>
                                                        <option value="male">{t('Male')}</option>
                                                        <option value="female">{t('Female')}</option>
                                                    </select>
                                                    <i className="fa fa-user" aria-hidden="true"></i>
                                                </div>


                                                <div className='position-relative'>
                                                    <input required className="form-control" type="email" placeholder={t('Email Address')} value={email} onChange={e => setEmail(e.target.value)} />
                                                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                                                </div>

                                                <div className='position-relative'>
                                                    <input required className="form-control" type="password" placeholder={t("Password")} value={password} onChange={e => setPassword(e.target.value)} />
                                                    <i className="fa fa-lock" aria-hidden="true"></i>
                                                </div>
                                                <div className='position-relative'>
                                                    <input className="form-control" type="text" placeholder={t("Refferal Code")} value={refferal} onChange={e => setRefferal(e.target.value)} />
                                                    <i className="fa fa-user-plus" aria-hidden="true"></i>
                                                </div>

                                                <div className="form-group form-check">
                                                    <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} className="form-check-input" id="exampleCheck1" />
                                                    <label className="form-check-label ml-3" for="exampleCheck1">{t('By checking this box you are agreeing to our')} &nbsp;<a style={{ color: '#dd3333' }} href="/privacy-policy">{t('Privacy Policy')}</a></label>
                                                </div>

                                                <button disabled={!checked} type='submit' className='btn'>{t('Register')}</button>

                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Lost your password */}

                <div className="modal fade signin-modal" id="lostPasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Forgot Password</h5>
                                <button id='lost_password_modal_close_btn' type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="tab-pane fade show active" id="logIn" role="tabpanel" aria-labelledby="logIn-tab">

                                    <form onSubmit={e => forgotPassword(e)}>
                                        <div className='position-relative'>
                                            <input value={email} readOnly={showOtp} onChange={e => setEmail(e.target.value)} required className="form-control" type="email" placeholder={t("Email")} />
                                            <i className="fa fa-user-o" aria-hidden="true"></i>
                                        </div>

                                        {showOtp && <div className='position-relative'>
                                            <input className="form-control" type="number" value={otp} onChange={e => setOtp(e.target.value)} required placeholder="Enter OTP for validation" />
                                            <i className="fa fa-lock" aria-hidden="true"></i>
                                        </div>}

                                        <button className='btn' type='submit'>Submit</button>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}
