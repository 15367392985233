import React from 'react'

export default function Privacy() {
  return (
    <div className='bg-white text-body py-5 privacy-page'>
      <div className='container'>
        <h1><strong>Madreambody Privacy Policy</strong></h1>
        <p><strong>Last Modified on December 26, 2021.</strong></p>
        <h2><strong>Scope &amp; Introduction</strong></h2>
        <p>Madreambody values your privacy. This Privacy Policy details important information about the collection, use, disclosure, retention and protection of your personal information. Madreambody provides this notice about our practices to help you make an informed decision about whether to use or continue using the Site (as defined below).<br /> </p>
        <p>Please note that any content posted by you onto the Site, including but not limited to reviews, photos, videos, questions, answers, or profile updates, becomes published content and is publicly available for anyone in the world to view. Madreambody will display this content on the Site and may distribute it through other third-party sites and services in its sole discretion.</p>
        <p>By registering for an account on the Site or accessing the Site, you are accepting the terms of this Privacy Policy and you are consenting to the collection, use, disclosure and retention of your personal information in accordance with the practices described in this policy.</p>
        <p>This privacy policy applies to realself.com, our Madreambody mobile applications (whether accessed by a tablet, mobile phone or other similar device) and our other websites (collectively, "Site") whether you browse our Site (without registering for an account) or register for an account to use the Site as either a user or health care provider. This privacy policy also applies to information we collect in email, text and other electronic messages between you and us.&nbsp;</p>
        <p>We have created a separate <a href="https://www.realself.com/real_self_cookie_policy.html">Cookie Policy</a> to detail our use of cookies and tracking technologies. The Cookie Policy forms part of this privacy policy and the Cookie Policy may be updated from time-to-time as specified in this privacy policy.&nbsp;</p>
        <h3><strong>Changes to this Privacy Policy</strong></h3>
        <p>This Privacy Policy may be revised periodically and this will be reflected by the "effective date" above. If we make any material changes in the way in which your personal information is collected, used or disclosed, then we will notify you through the Site or by email. You are advised to review this privacy policy periodically for any changes. Changes to this privacy policy are effective when they are posted on this page, and your continued use of the Site after we make changes is deemed to be acceptance of those changes. If you object to any of the changes to the privacy policy, then you can close your account by contacting us.</p>
        <h2><strong>Information Madreambody Collects</strong></h2>
        <p>Madreambody acts as a data controller when it collects personal information from you when you visit our Site or when you register to use the Site or access your account through the Site. For purposes of this privacy policy, &ldquo;personal information&rdquo; means any data and information relating to an identified or identifiable natural person. Personal information does not include information that you post on the Site or that is publicly available from government records, as well as de-identified or aggregated consumer information.</p>
        <p>In the last twelve months, Madreambody has collected the following types of personal information from the following sources:</p>
        <h3><strong>Information Madreambody receives from you: </strong></h3>
        <ul>
          <li>You provide us with personal information when you register on the Site and update your account. This information may include: first and last name, geographic location, email address username and password.</li>
          <li><strong>Form Completion.</strong> You may also provide us with your personal information when you voluntarily fill in forms on our Site.</li>
          <li><strong>Purchases &amp; Promotions.</strong> When you purchase paid products or services or participate in giveaways or sweepstakes within the Site, you may provide us with personal information in order to process your payment or entry. As explained below, we use a third party payment processor to process payments on our behalf and we never have access to, store or collect your payment card details</li>
          <li><strong>User Interactions. </strong>You may provide personal information through communications or chats with other users on the Site. You understand that other users may contact you through direct messages and comments on the Site. Any messages that you send to other users through the Site may not be private or confidential if either you or the recipient of the message discloses it to another person or chooses to publicly post it. In addition, Madreambody may monitor these messages and not deliver any messages that RealSelf, in its sole discretion, believes to be spam, fraudulent or otherwise violative of the Madreambody Terms of Service or Code of Conduct.</li>
          <li><strong>Other Interactive Features.</strong> You also provide Madreambody with your information when you use certain features on the Site, such as submitting reviews, comments, questions and other postings on the Site (&ldquo;User Contributions&rdquo;). You understand that your User Contributions are published or displayed on public areas of the Site, or transmitted to other users of the Site or third parties. Your username (not your email address) is displayed to other users when you post User Contributions to the Site. If you use your first or last name as part of your username or your username is discoverable on the internet, then you are making this personal information publicly available and understand that other users may view this personal information and may be able to identify you from this personal information. Madreambody cannot control the actions of other users of the Site with respect to your User Contributions.</li>
          <li><strong>Troubleshooting &amp; Bug Reporting.</strong> You may also provide us with your personal information when you report a problem with our Site.</li>
        </ul>
        <h3><strong>Information Madreambody collects automatically:</strong></h3>
        <ul>
          <li><strong>Cookies Information.</strong> When you visit the Site, we (and third parties with whom we partner) may send one or more cookies - a small text file containing a string of alphanumeric characters - to your computer that uniquely identifies your browser. The cookies may transmit information about you and your use of the Site such as your browser type, IP address, advertisement data and the date and time of your use of the Site. For more information about how Madreambody uses cookies and other tracking technologies, see our <a href="https://www.realself.com/real_self_cookie_policy.html">Cookie Policy</a>.</li>
          <li><strong>Log File Information.</strong> When you use the Site, our servers automatically record certain information that your web browser sends whenever you visit any website. These server logs may include information such as your web request, Internet Protocol ("IP") address, browser type, browser language, referring/exit pages and URLs, platform type, number of clicks, domain names, landing pages, pages viewed and the order of those pages, the amount of time spent on particular pages and the date and time of your requests. Madreambody collects this information in order to improve the Site.</li>
          <li><strong>Web Beacons.</strong> When you use the Site, we may employ third party web beacons or "clear gifs" which are used to track the online usage patterns of our users anonymously (i.e., in a non-personally-identifiable manner). In addition, we may also use web beacons in HTML-based emails sent to our users to track which emails are opened by recipients. Madreambody uses web beacons in order to measure the effectiveness of our offerings and content. For more information about how Madreambody uses web beacons, see our <a href="https://www.realself.com/real_self_cookie_policy.html">Cookie Policy</a>.</li>
          <li><strong>Location Information and Device Information.</strong> When you access the Site from a mobile device or tablet, we may be able to collect your location information if this feature is enabled on your device or provided via Device ID or unique identifier. In addition, if you are accessing the Site from your computer, we may be able to collect the general location information associated with your IP address. Madreambody uses this information to show you content relevant to your geographic location.</li>
          <li><strong>Third-Party Use of Cookies and Other Tracking Technologies.</strong> Some content or applications, including advertisements, on the Site are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons and other tracking technologies to collect information about you when you use our Site. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content. Madreambody does not control these third parties&rsquo; tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For more information about how you can opt out of receiving targeted advertising from many providers, see <a href="https://www.realself.com/real_self_privacy_policy.html#yourchoices">Your Choices</a>.</li>
        </ul>
        <h3><strong>Information Madreambody receives from other sources:</strong></h3>
        <ul>
          <li><strong>Social Media. </strong>If you connect your Facebook or other third-party social media account to your Madreambody account, you understand that Madreambody may receive information about you from those third-party sites and agree that information may be transmitted into your Madreambody account. The information collected by Madreambody from third party social media sites is limited to your contact information and username, and may depend on your privacy settings with those third-party sites. As a result, you should consult the privacy policies of those third-party social media sites. The Madreambody website may also host plug-ins or other technologies from various social media sites. When you click on a link provided by a social media plug-in, you are voluntarily establishing a direct connection with the social media site and you should consult the privacy policy of the social media site to learn more about their privacy practices. This privacy policy does not apply to the third party social media websites.</li>
        </ul>
        <h2><strong>How Madreambody Uses Information</strong></h2>
        <p>Madreambody uses the information that we collect about you or that you provide to us for a range of different business purposes. We use your personal information to track usage of the Site, to market the Site, to improve the Site features and functionality, to backup our systems and to provide customer support. Our legal basis for processing your personal information is to carry out our legal obligations, to fulfill a contract with you, to protect your vital interests, for our legitimate business interests, to provide you with notices, and for other purposes with your consent.</p>
        <h3><strong>We use your personal information:</strong></h3>
        <ul>
          <li>To provide our Site and its contents to you.</li>
          <li>To provide customer support, including responding to your requests and contacting you about the requests.</li>
          <li>To provide you notices about your account (such as updates to this privacy policy, the Terms of Service, Code of Conduct or for other customer service purposes).</li>
          <li>To provide other services requested by you, such as to connect you to other users or health care providers on the Site. If you send direct messages or electronic messages through Madreambody to other users, please note that Madreambody may store these messages in order to deliver them and allow you or the recipient of the message to manage them. When contacting you to connect you to health care providers on the Site, Madreambody may contact you via email, SMS/text messages or by telephone. Message and data rates may apply.</li>
          <li>To monitor the usage of our Site, improve the quality and design of the Site, and create new features, functionality, and services.</li>
          <li>To investigate illegal&nbsp; activities on the Site or activities that violate our <a href="https://www.realself.com/terms-of-service">Terms of Service</a>.</li>
          <li>To detect, prevent and address technical issues, and improve the security of our Site.</li>
          <li>To validate the authenticity of and display the reviews and other content provided by you to other users.</li>
          <li>To gather analysis or valuable information so that we can improve our Site.</li>
          <li>To customize advertising you may see on our Site.</li>
          <li>To contact you by email in order to offer you special promotions or poll your opinions through surveys or questionnaires.</li>
          <li>To contact you by email or through your account to notify you of major Site changes.</li>
          <li>To measure the performance of email marketing campaigns.</li>
          <li>To allow you to participate in interactive features on our Site.</li>
          <li>To fulfill any other purposes for which you provide it.</li>
        </ul>
        <h3><strong>With your consent, which you may withdraw at any time, Madreambody also uses your personal information:</strong></h3>
        <ul>
          <li>To send commercial or marketing messages.</li>
          <li>To provide you with a personalized user experience by showing you content relevant for your geographic location.</li>
          <li>To customize advertising you may see on third party websites.</li>
          <li>To provide you with marketing from third parties.</li>
          <li>For any other purpose, with your consent.</li>
        </ul>
        <h2><strong>When Madreambody Discloses Information</strong></h2>
        <h3><strong>Third Party Service Providers </strong></h3>
        <p>We provide personal information and non-personally-identifiable information to our subsidiaries, affiliated companies, or other businesses or persons for the purpose of processing such information on our behalf, including, but not limited to, providing analytics, monitoring our Site, A/B testing, marketing, distribution of surveys or giveaway programs. We require that these parties agree to process such personal information in compliance with this privacy policy and any other appropriate confidentiality and security measures as well as for the sole purpose of providing the specific service to Madreambody. The third party service providers are not permitted to share such personal information or use it for any other purpose.<br /> </p>
        <p>In the last twelve months, we have disclosed the following categories of personal information for a business purpose:</p>
        <ul>
          <li>Identifiers</li>
          <li>California Customer Records personal information categories</li>
          <li>Protected classification characteristics under state or federal law</li>
          <li>Internet or other similar network activity</li>
          <li>Geolocation data</li>
        </ul>
        <p>We disclose your personal information for a business purpose to the following categories of third parties:</p>
        <ul>
          <li>Affiliates</li>
          <li>Service providers</li>
          <li>Third parties to whom you or your agents authorize us to disclose your personal information in connection with the products or services we provide to you</li>
        </ul>
        <p>We do not sell your personal information.</p>
        <p>We do share anonymous aggregated user data (such as usage data, referring/exit pages and URLs, platform types, number of clicks, etc.) with interested third parties to assist them in understanding the usage patterns for certain content, services, advertisements, promotions, and/or functionality on the Site.</p>
        <h3><strong>Fulfill Your Requests</strong></h3>
        <p>We will always use your personal information to fulfill the purpose for which it had been requested. For example, if you submit your first and last name, email address, telephone number, treatment desired and questions or comments through a form on a specific health care provider&rsquo;s profile page, then Madreambody will disclose this information to the health care provider identified on the form. In addition, if you submit your mobile phone number through such a form, then you understand and consent to Madreambody and/or the health care provider sending you text messages. You understand that standard text messaging fees and data rates may apply. Madreambody will provide transparency and state the purpose and terms at the point of collection of such information, and the information you provide through such forms will not used by Madreambody or any other purpose.<br /> </p>
        <p>If you purchase products and services through the Site, the payment information is transmitted directly via an encrypted connection to our third-party payment processors. Madreambody uses Braintree and Stripe to process payments, and Braintree and Stripe store your personal and payment information in accordance with their respective privacy policies. Madreambody does not collect or store your payment information. You can learn more about Stripe&rsquo;s privacy practices by reviewing Stripe&rsquo;s privacy policy. You can learn more about Braintree&rsquo;s privacy practices by reviewing Braintree&rsquo;s privacy policy. Madreambody does collect your name, email address and telephone number in order to process your payment and this information is stored by Madreambody in accordance with this privacy policy.</p>
        <h3><strong>Third-party Advertisers </strong></h3>
        <p>We do not share your personal information (such as name or email address) with other third-party companies for their commercial or marketing use without your consent or except as part of a specific program or feature for which you will have the ability to opt-in or opt-out.<br /> </p>
        <p>Madreambody may allow other companies, called third-party ad servers or ad networks, to serve advertisements within the Site. These third-party ad servers or ad networks use cookies, web beacons and other tracking technologies to send, directly to your browser, the advertisements and links that appear on the Site. They automatically receive your IP address when this happens. In addition, the third-party ad servers or ad networks may use information about your visits to our Site and other websites to provide advertisements about goods and services of interest to you. You should consult the respective privacy policies of these third-party ad servers or ad networks. This privacy policy does not apply to, and we cannot control the activities of, such other advertisers or web sites. Please see our <a href="https://www.realself.com/real_self_cookie_policy.html">Cookie Policy</a> to learn more about the cookies and web beacons used by the third-party ad servers and networks.</p>
        <h3><strong>Legal Requests and to Comply with State and Federal Laws </strong></h3>
        <p>We may disclose personal information if such action is necessary to comply with a legal obligation, to comply with state and federal laws or to respond to a court order, subpoena, or search warrant. Madreambody may disclose any of your messages or other content as part of our investigations into the use and operation of the Site.</p>
        <p>Madreambody also reserves the right to disclose personal information that Madreambody believes, in good faith, is appropriate or necessary to enforce our Terms of Service, take precautions against liability, to investigate and defend itself against any third-party claims or allegations, to protect the security or integrity of the Site, and to protect the vital interests, property, or personal safety of RealSelf, our users or others.</p>
        <h3><strong>In the Event of Merger, Sale, or Bankruptcy </strong></h3>
        <p>In the event that Madreambody is acquired by or merged with a third-party entity, we reserve the right, in any of these circumstances, to transfer or assign the information we have collected from you as part of such merger, acquisition, sale, or other change of control. In the unlikely event of our bankruptcy, insolvency, reorganization, receivership, or assignment for the benefit of creditors, or the application of laws or equitable principles affecting creditors rights generally, we may not be able to control how your information is treated, transferred, or used.</p>
        <h2><strong>Your Choices</strong></h2>
        <p>Madreambody strives to provide you with choices regarding the personal information you provide to us, and we understand that you have rights in your personal information such as: the right to access, correct, delete, object to and restrict the use of your personal information. We also understand that you have the right to obtain a copy of your personal information from us and to withdraw your consent of the processing of your personal information by us. We have created mechanisms to provide you with the following control over your personal information:</p>
        <h3><strong>Consumer Rights and Access</strong></h3>
        <ul>
          <li>You can choose not to provide personal information through our Site, in which case Madreambody may not be able to provide certain services to you or respond to your requests.</li>
          <li><strong>Access, Changes, &amp; Deletion. </strong>You can access, review and change your personal information by logging into your account on the Site and visiting your account profile page. You may also send us an email at info@madreambody.com to request access to, correct or delete any personal information that you have provided to us. In some cases, we may not be able to delete your personal information except by also deleting your user account.</li>
          <li><strong>Madreambody Emails.</strong> If you do not wish to have your email address or other contact information used by us to promote our Site or other products or services, you can opt-out at any time by logging into the Site and adjusting your user preferences in your account profile or by sending us an email stating your request to info@madreambody.com If we have sent you a promotional email, you may unsubscribe to receive future emails via the unsubscribe link in that email.</li>
          <li><strong>Copy of Personal Information.</strong> You may also request a copy of your personal information that you have provided to us directly by sending an email to info@madreambody.com We will provide you a copy of your personal information in a commonly used electronic form.</li>
          <li><strong>Closing Accounts.</strong> You may also close your account by contacting Madreambody Community Support. Madreambody will disassociate your User Contributions from your account. However, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other Site users. In addition, Madreambody will continue to retain certain information about you in accordance with the privacy practices in this policy or if required to do so as a result of a legal obligation. This provision does not apply to the accounts of doctors and other service providers, as Madreambody will continue to display public provider information for the benefit of consumers regardless of whether an account with Madreambody is open or closed.</li>
        </ul>
        <h3><strong>Account Verification and Process</strong></h3>
        <p>In order to fulfill the above requests, Madreambody may ask you to verify your identity before responding to such requests. We endeavor to respond to verifiable consumer requests within 30 days of receipt. If we require more time, we will inform you of the reason and the anticipated additional amount of time required. Further, your right to access your personal information may not apply in some cases, including where providing access is unreasonably burdensome or expensive under the circumstances, where such requests are too frequent (requests for a copy of your personal data are typically limited to two times per year), or where it would violate the rights of someone other than the individual requesting access.</p>
        <h3><strong>Advertising and Analytics</strong></h3>
        <p>If you do not want us to use information that we collect or that you provide to us to deliver advertisements, for analytics or remarketing services, then you may opt-out as described below.</p>
        <ul>
          <li>For analytics, we use Google Analytics, which is a web analytics service that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Site. This data may be shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network. You can opt-out of having your activity on the Site available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents Google Analytics JavaScript from sharing information with Google Analytics about visits activity. For more information on the privacy practices of Google, please visit Google Privacy &amp; Terms web page.</li>
          <li>Madreambody uses Google AdSense &amp; DoubleClick Cookie on the Site. Google, as a service provider, uses cookies to serve ads on our Site. Google&rsquo;s use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Site or other websites on the internet. You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads settings web page. You can also opt out of receiving targeted ads from members of the Network Advertising Initiative (&ldquo;NAI&rdquo;) on the NAI&rsquo;s website.</li>
          <li>Madreambody currently uses Google AdWords, which is a remarketing service provided by Google. You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads by visiting the Google Ads settings page. In addition, Google also recommends installing the Google Analytics opt-out browser add-on for your web browser, which is available. This tool provides visitors with the ability to prevent their data from being collected and used by Google Analytics.</li>
        </ul>
        <h3><strong>Retention of Personal Information</strong></h3>
        <p>Madreambody will retain your personal information only for as long as is necessary for the purposes set forth in the privacy policy. Madreambody will retain and use your personal information to the extent necessary to comply with our legal obligations (for example, if we are required to retain your personal information to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <h2><strong>Other Important Information</strong></h2>
        <h3><strong>Our Commitment to Data Security</strong></h3>
        <p>Madreambody uses commercially reasonable physical, managerial, and technical safeguards to preserve the integrity and security of your personal information. For example, to protect your privacy and security, we take reasonable steps (such as requesting a unique password) to verify your identity before granting you profile access or making corrections. You are responsible for maintaining the secrecy of your unique password and account information at all times. We cannot, however, ensure or warrant the security of any information you transmit to the Site and you do so at your own risk. Once we receive your information, RealSelf makes commercially reasonable efforts to ensure the security of your information. However, please note that this is not a guarantee that such information may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>
        <p>If Madreambody learns of a security systems breach, then we may attempt to notify you electronically so that you can take appropriate protective steps. Madreambody may post a notice on the Site if a security breach occurs. Depending on where you live, you may have a legal right to receive notice of a security breach in writing. To receive a written notice of a security breach (or to withdraw your consent from receiving electronic notice) you should notify us using our <a href="https://help.realself.com/hc/en-us/requests/new">feedback form</a>.</p>
        <h3><strong>Children's Privacy</strong></h3>
        <p>Protecting the privacy of children is important to RealSelf. For that reason, Madreambody does not knowingly collect or maintain personal information from persons under 18 years of age, and no part of the Site is directed to persons under 18. If you are under 18 years of age, then please do not use or access the Site at any time or in any manner. If Madreambody learns that personal information of persons under 18 years of age has been collected without verified parental consent, then Madreambody will take the appropriate steps to delete this information.</p>
        <h3><strong>Visiting the Site from outside the United States</strong></h3>
        <p>The Site is hosted in the United States. If you are accessing the Site from outside the United States, please be advised that the information you provide Madreambody may be transferred to, processed and stored in the United States where the Madreambody servers are located and our central database is operated. You acknowledge and understand that the data protection and other laws of the United States might not be as comprehensive as those in your country. Through your continued use of the Site (which is governed by U.S. law, this privacy policy, and our Terms of Service), you consent to the transfer of your personal information to the United States.</p>
        <h3><strong>Call Monitoring and Recording</strong></h3>
        <p>Telephone calls to or from Madreambody may be recorded and monitored by us. Madreambody records and monitors telephone calls for user support services and for staff training purposes. Madreambody retains the recordings for a reasonable period of time in order to fulfill the purpose for which the telephone calls were recorded and then the recordings are deleted.</p>
        <h3><strong>How We Respond to Do Not Track Signals</strong></h3>
        <p>Madreambody currently does not respond to "Do Not Track" signals because there is not an industry or legal standard for such signals.</p>
        <h3><strong>Automated Decision-Making</strong></h3>
        <p>Madreambody does not use any personal information provided by you for the purposes of automated decision-making.</p>
        <h3><strong>Links to Other Sites</strong></h3>
        <p>Our Site may contain links to other websites that are not operated by RealSelf. If you click on a third-party link, you will be directed to that third-party&rsquo;s website. This privacy policy does not apply to the third-party websites and Madreambody encourages you to review the privacy policy of the third-party websites you visit.</p>
        <h2><strong>How You Can Contact Us</strong></h2>
        <p className='mb-0'>If you have any questions or complaints about the use or disclosure of your personal information, this privacy policy, or our privacy practices, please contact Madreambody by sending an email to our data protection office at info@madreambody.com. In addition, you may contact Madreambody via info@madreambody.com or by writing to us at the following</p>
      </div>
    </div>
  )
}
