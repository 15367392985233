import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { userContext } from '../Context/UserContext'
import OwlCarousel from "react-owl-carousel";
import { node_url, url } from 'src/Helpers/helpers';
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const { t } = useTranslation()
  const [images, setImages] = useState([])
  const { setLoad } = useContext(userContext)
  useEffect(() => {
    async function fetchData() {
      setLoad(true)
      const response = await fetch(url + 'getAllFooters');

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          setImages(data.list)
        } else {
          toast.error(data.message)
        }
      } else {
        toast.error("Oops something went wrong!")
      }
    }

    fetchData().catch(err => {
      toast.error(err.message)
      setLoad(false)
    })
  }, [])
  return (
    <div className='footer-div-Y'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6'>
            <img className='img-fluid logo-img' src="/assets/images/logo.png" alt="" />
            <p className='mission-para'>{t('At Madreambody, our mission is to provide a safer and affordable platform to enable our users achieve their dream bodies with our trusted and verified beauty, cosmetic & personal care professionals worldwide')}</p>
          </div>
          <div className='col-md-3 col-sm-6'>
            <h4>{t('Navigation')}</h4>
            <div className='menu-options'>
              <div className='menu-opt'>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <a href="/about">{t('About Us')}</a>
              </div>
              <div className='menu-opt'>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
                <a href="/register_business">{t('Owner Registration')}</a>
              </div>
            </div>
          </div>

          <div className='col-md-3 col-sm-6'>
            <h4>{t('Contact Us')}</h4>
            <div className='address-div'>
              <p className='mb-0'>{t('LONDON OFFICE : 120 High Road, N2 9ED, London, UK. TEL: 020 32830996')}</p>
              <a href="mailto:info@madreambody.com">info@madreambody.com</a>
            </div>
            <div className='social-icons'>
              <a href="https://www.instagram.com/ma_dreambody/" target="__blank"><img src="/assets/images/flat_instagram.png" alt="" /></a>
              <a href="https://www.youtube.com/channel/UCjG4OOTAxO1pvLOX7bDgSvA" target="__blank"><img src="/assets/images/flat_youtube.png" alt="" /></a>
              <a href="https://www.facebook.com/Madreamboby/" target="__blank"><img src="/assets/images/flat_facebook.png" alt="" /></a>
              <a href="https://twitter.com/Madreambody_" target="__blank"><img src="/assets/images/flat_twitter.png" alt="" /></a>
            </div>
            <div className="d-flex justify-conetent-between my-3">
              <a target="_blank" href='https://apps.apple.com/app/madreambody/id6446048989'>
                <img src="assets/images/download-from-app-store.png" className='img-fluid w-auto mr-2' style={{ height: "50px" }} alt="" />
              </a>

              <a target="_blank" href='https://play.google.com/store/apps/details?id=com.madreambody'>
                <img src="assets/images/download-from-play-store.png" className='img-fluid w-auto ml-2' style={{ height: "50px" }} alt="" />
              </a>
            </div>
          </div>
        </div>

        {/* Carousel section */}
        {/* <div id='footer-carousel' class="owl-carousel">
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg" alt="" />
          </div>
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg" alt="" />
          </div>
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg" alt="" />
          </div>
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg " alt="" />
          </div>
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg " alt="" />
          </div>
          <div className='company-logo'>
            <img src="/assets/images/company-1.jpeg " alt="" />
          </div>
        </div> */}

        {images.length > 0 && (
          <OwlCarousel responsive={{
            0: {
              items: 1,
            },
            500: {
              items: 2,
            },
            780: {
              items: 3,
            },
            1000: {
              items: 4,
            },
          }} className="owl-theme custom-nav-btns" loop margin={10} dots={false} autoplay autoplayTimeout={2000} nav>
            {images.map((item, index) => <div key={index} className='company-logo'>
              <img src={`${node_url}${item?.url}`} alt="" />
            </div>)}
          </OwlCarousel>
        )}

        {/* copyrights section */}
        <div className='copyright-div'>{t('The information on Madreambody is intended for educational purposes only. While we do connect people with certified beauty, cosmetic & personal care professionals, we don’t provide medical consultations, diagnosis, or advice. If you’re experiencing a medical issue, please contact your emergency service or a personal care professional.')} &nbsp;&nbsp;&nbsp;
          {t('© 2021 Madreambody LTD All Rights Reserved.')} <a href='/terms-conditions'>{t('Terms & Conditions')}</a> | <a href='/privacy-policy'>{t('Privacy Policy')}</a>
        </div>

        <div className="web-amplifier">
          <p className='mb-0'>Made with love <img src="/assets/images/love.png" alt="" /> by <a target='_blank' href="https://web-amplifier.com/">Web Amplifier</a></p>
        </div>
      </div>

    </div>
  )
}
