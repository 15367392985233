import React from 'react'
import Footer from 'src/Components/Footer'
import Header from 'src/Components/Header'


export default function UserLayout(props) {
  return (
    <>
        <Header custom_class={props.custom_class} />
        {props.children}
        <Footer />
    </>
  )
}
