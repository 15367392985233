import React from "react";
import CustomInput from "../Profile/Listing/utils/CustomInput";

function BookingConfirmation() {
  const [firstName, setFirstName] = React.useState("");
  return (
    <div>
      <h3 className="px-5">Booking Confirmation</h3>
      <div
        className="bg-white px-5 row py-5 mt-5"
        style={{
          color: "black",
        }}
      >
        <div className="col-md-8">
          <h4>Personal Details</h4>
          <div className="d-flex mt-5">
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"First Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"Last Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
          </div>
          <div className="d-flex mt-4">
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"First Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"Last Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
          </div>
          <div className="d-flex mt-4">
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"First Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"Last Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
          </div>

          <div className="d-flex mt-4">
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"First Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
            <div className="w-100 pr-4">
              <CustomInput
                fontLight
                label={"Last Name"}
                required
                setvalue={setFirstName}
                value={firstName}
              />
            </div>
          </div>

          <button
            className="custom-button addPhotosButton mt-4"
            style={{
              width: "180px",
              height: "40px",
            }}
          >
            Verify Phone Number
          </button>
        </div>

        <div className="col-md-4">
          <div className="bookingConfirmation__rightDivTopImageDiv">
            <div>
              <h5>Make up By Vick</h5>
              <p>Birmingham Uk</p>
            </div>
          </div>

          <div className="singleServicePage__customAddReview">
            <h5 className="custom-heading">
              <i class="fa fa-calendar-check-o" aria-hidden="true"></i>
              <span className="ml-2">Booking Summary</span>
            </h5>
            <hr />
            <div className="d-flex justify-content-between mt-3 bookingConfirmation__redHover">
              <h6>Date</h6>
              <span>2022-07-02</span>
            </div>
            <div className="d-flex justify-content-between mt-2 bookingConfirmation__redHover">
              <h6>Time</h6>
              <span>08:00 un m - 08:00</span>
            </div>
            <hr />
            <p
              style={{
                color: "rgb(168, 168, 168)",
              }}
            >
              Additional Services
            </p>
            <div className="d-flex justify-content-between mt-2 bookingConfirmation__redHover">
              <h6>Party Glam 52.5</h6>
              <span>Free</span>
            </div>
            <hr />
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingConfirmation;
