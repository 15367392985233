import React from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { node_url, url } from "src/Helpers/helpers";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import CustomSlider from "src/Components/ReactRangeSlider/RangeSlider";
import { useLocation } from 'react-router-dom'
import { userContext } from '../../Context/UserContext'

const ParticularSearchPage = () => {
  const [options, setOptions] = React.useState([]);
  const [currentOption, setCurrentOption] = React.useState("")
  const [page, setPage] = useState(1)
  const [value, setValue] = React.useState("");
  const [condition, setCondtion] = React.useState("Default Order")
  const { user, setLoad } = React.useContext(userContext)

  const [list, setList] = React.useState([])
  const [listGallery, setListGallery] = React.useState([])
  const [open, setOpen] = React.useState([])

  const [cities, setCities] = React.useState([])
  const [city, setCity] = React.useState("")

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  const category_url = useQuery().get('category');

  const city_url = useQuery().get('city')

  useEffect(() => {


    async function fetchCitiesFromListings() {
      setLoad(true)

      const response = await fetch(url + 'getAllCitiesBasedOnListings')

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          let result = []

          data?.list?.map((item) => {
            result.push({
              value: item.city,
              label: item.city
            })
          })

          setCities(result)
        } else {
          toast.error(data?.message)
        }

      } else {
        toast.error("Oops something went wrong!")
      }

    }

    fetchCitiesFromListings().catch(err => {
      setLoad(false)
      toast.error(err?.message)
    })

    async function fetchData() {
      setLoad(true)
      const response = await fetch(url + "grabAllCategoriesSubCategories");
      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();
        if (data.status == 200) {
          console.log(data);
          setOptions(
            data?.category_list?.map((item) => {
              return {
                value: item?.id,
                label: item?.name,
                url: item?.url
              };
            })
          );
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    }

    fetchData().catch((err) => {
      setLoad(false)
      toast.error(err.message);
    });

    let query_url = `fetchSearchPageListing?category=${category_url}&condition=${condition}&page=${page}&version=new`

    if (city_url && city_url.length > 0) {
      query_url = query_url + `&city=${city_url}`
    }


    fetchAllListing(query_url).catch(err => {
      toast.error(err.message)
    })

  }, [category_url]);

  async function fetchAllListing(query_url) {
    setLoad(true)
    const response = await fetch(url + query_url)
    setLoad(false)
    if (response.ok == true) {
      const data = await response.json();

      if (data.status == 200) {
        setList(data?.list)
        setListGallery(data?.list_gallery)
        setOpen(data?.open_status)
      } else {
        toast.error(data.message)
      }
    } else {
      toast.error("Oops something went wrong!")
    }
  }

  function changeCondition(condition_value) {
    setCondtion(condition_value)
    fetchAllListing(`fetchSearchPageListing?category=${category_url}&condition=${condition_value}&page=${page}&version=new`).catch(err => {
      setLoad(false)
      toast.error(err.message)
    })

  }

  function changeCity(value) {
    setCity(value)

    let category_slug = category_url
    if (currentOption.length > 0) {

      if (category_slug.length > 0) {
        category_slug = category_slug + ',' + currentOption.value.toString()
      } else {
        category_slug = currentOption.value.toString()
      }

    } else {
      category_slug = category_url
    }

    fetchAllListing(`fetchSearchPageListing?category=${category_slug}&condition=${condition}&city=${value.label}&page=${page}&version=new`).catch(err => {
      setLoad(false)
      toast.error(err.message)
    })
  }


  function changeCategory(value) {
    setCurrentOption(value)

    let category_slug = value.value

    let url = `fetchSearchPageListing?category=${category_slug}&condition=${condition}&page=${page}&version=new`
    if (city?.label) {
      url = url + `&city=${city?.label}`
    }
    fetchAllListing(url).catch(err => {
      setLoad(false)
      toast.error(err.message)
    })
  }

  function changePage(incondition) {
    let new_page = page
    if (incondition == "prev") {
      new_page = new_page - 1

    } else if (incondition == "next") {
      new_page = new_page + 1
    }

    let category_slug = category_url
    if (currentOption.length > 0) {

      if (category_slug.length > 0) {
        category_slug = category_slug + ',' + currentOption.value.toString()
      } else {
        category_slug = currentOption.value.toString()
      }

    } else {
      category_slug = category_url
    }

    if (city && city.value) {
      fetchAllListing(`fetchSearchPageListing?category=${category_slug}&condition=${condition}&city=${city.label}&page=${new_page}&version=new`).catch(err => {
        setLoad(false)
        toast.error(err.message)
      })
    } else if (city_url.length > 0) {
      fetchAllListing(`fetchSearchPageListing?category=${category_slug}&condition=${condition}&city=${city_url}&page=${new_page}&version=new`).catch(err => {
        setLoad(false)
        toast.error(err.message)
      })
    } else {
      fetchAllListing(`fetchSearchPageListing?category=${category_slug}&condition=${condition}&page=${new_page}&version=new`).catch(err => {
        setLoad(false)
        toast.error(err.message)
      })
    }

    setPage(new_page)

  }

  return (
    <div className="particularsearchpagei">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8">
            <div className="">
              <ul className="nav nav-tabs">
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    {condition}
                  </a>
                  <div className="dropdown-menu">
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Default Order")}>
                      Default Order
                    </p>
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Most Reviewed")}>
                      Most Reviewed
                    </p>
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Newest Listings")}>
                      Newest Listings
                    </p>
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Oldest Listings")}>
                      Oldest Listings
                    </p>
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Alphabetically")}>
                      Alphabetically
                    </p>
                    <p class="dropdown-item mb-0" onClick={() => changeCondition("Most Views")}>
                      Most Views
                    </p>
                  </div>
                </li>
              </ul>
            </div>

            <div className="row card-divs">
              {list.length > 0 ? list.map((item, index) => (
                <div className="col-12 col-lg-6" key="index">
                  <div className="WW-recommend-div mt-4">
                    <div>
                      <a className="carousel-card-anchor" href={`/single_service_page?slug=${item?.slug_name}`}>
                        <div
                          className="carousel-card"
                          style={{
                            backgroundImage: `url("${node_url}${item?.image_url}")`,
                          }}
                        >
                          <div className="booking-status">

                            <div className="price-tag">
                              <i class="fa fa-tag" aria-hidden="true"></i>
                              <span>{item?.currency} {item.minimum_booking} - {item?.currency} {item.maximum_booking}</span>
                            </div>
                          </div>

                          {item?.max_discount > 0 && <div style={{ position: 'absolute', top: 10, right: 10, backgroundColor: 'red', color: 'white', padding: '5px 25px', borderRadius: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <p className="mb-0 mt-2 font-weight-bold">{item?.max_discount}%</p>
                            <p className="mt-0 font-weight-bold">OFF</p>
                          </div>}
                          <div className="inner-content">
                            <span className="tag-span">{item?.category_name}</span>
                            <h3>{item?.business_name} </h3>
                            <span className="address-div">
                              {item?.location_address}
                            </span>
                          </div>
                        </div>
                        <div className="review-div d-flex">
                          {item?.reviews > 0 ? (
                            <>
                              <ReactStars
                                count={5}
                                value={parseFloat(item?.avg_reviews)}
                                size={24}
                                edit={false}
                                activeColor="#ffd700"
                              />
                              <p className="mb-0 ml-2" style={{ fontSize: 18 }}>
                                {item?.reviews} Reviews
                              </p>
                            </>
                          ) : <span>No reviews yet</span>}


                        </div>
                      </a>
                    </div>

                  </div>
                </div>
              )) : <div>
                <p className="text-center mt-3 font-weight-bold">No Listings Found With This Search Criteria</p>
              </div>}
            </div>

          </div>
          <div className="col-12 col-lg-4 ranges-filter">
            <div className="heading">
              <h4 className="main-heading">Filters</h4>
            </div>
            <div className="">
              <form action="">
                <div className="form-group">
                  <Select placeholder="Select City" options={cities} value={city} onChange={changeCity} className="w-100 mb-3" />
                  <Select placeholder="Select Service" options={options} value={currentOption} onChange={changeCategory} className="w-100" />
                </div>
                {/* <div className="range-1">
                  <h5 className="range-output-1">50km</h5>
                  <p className="range-output-description-1">
                    Radius around selected destination
                  </p>
                  <CustomSlider
                    value={value}
                    onChange={function (value) {
                      setValue(value);
                    }}
                  />
                </div> */}
                <div className="border-bottom border-bottom-div"></div>
                <div className="range-2">
                  <h5 className="range-output-2">Price Filter</h5>
                  <p className="range-output-description-2">
                    Select min and max price range
                  </p>
                  <div class="price-input">
                    <div class="field">
                      <span>Min</span>
                      <input type="number" class="input-min" value="2500" />
                    </div>
                    <div class="separator">-</div>
                    <div class="field">
                      <span>Max</span>
                      <input type="number" class="input-max" value="7500" />
                    </div>
                  </div>
                  <div class="slider">
                    <div class="progress"></div>
                  </div>
                  <div class="range-input">
                    <input
                      type="range"
                      class="range-min"
                      min="0"
                      max="10000"
                      value="2500"
                      step="100"
                    />
                    <input
                      type="range"
                      class="range-max"
                      min="0"
                      max="10000"
                      value="7500"
                      step="100"
                    />
                  </div>
                </div>

                {/* <div className="submit-btn">
                  <button type="button" onClick={()=>handleSearch()} className="btn btn-block filter-submit">
                    Search
                  </button>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="container px-0 d-flex justify-content-center my-4 custom-pagination align-items-center">
        <button onClick={() => changePage("prev")} className="prev-btn">Prev</button>
        <span>{page}</span>
        <button onClick={() => changePage("next")} className="next-btn">Next</button>
      </div>
    </div>
  );
};

export default ParticularSearchPage;
