import React from 'react'

export default function Terms() {
    return (
        <div>
            <p>
                <strong>
                    We are Letsmakeover LTD whose trading name is Madreambody a company registered in England and Wales under number 13130449 whose registered address office is at 158 Cheapside Birmingham B12 0PN. Madreambody has separate Terms of Service for our community members and for our participating Health care providers.These are detailed below.
                </strong>
            </p>

            <p>Madreambody Community Member Terms of Service  </p>

            <p>
                Please read Madreambody  Terms of Service  carefully. These Terms and conditions will apply to the purchase of the services and goods by you ( the customers or you). The use of “you” or “your” in these Terms may refer to Madreambody community members, a health care provider accessing the Site (as defined below) as a consumer, or anyone accessing the Site (as defined below). These Terms govern your use of the website, Madreambody.com, and the services, information, and products available on or through the website (collectively, “Site”) whether you access the Site through your computer, mobile device, tablet or any Madreambody app.
            </p>

            <p>
                You accept and agree to be bound by these Terms, these Privacy Policies, Code of Conducts and any other policies in place when you access or use the Site, or by clicking to accept when you sign up for a service, make a purchase, or register for an account with Madreambody. If you do not agree to abide by or be bound by these Terms or any of the other policies of Madreambody, then do not sign up for a service, make a purchase, register for an account, access or use the Site.
            </p>

            <h2>1.Modification </h2>

            <p>
                Madreambody reserves the right to make changes to the Site, these Terms and other policies. If Madreambody makes material changes to these Terms or other policies, then Madreambody will either notify you by email or by posting a notice on the Site prior to the effective date of the changes. Madreambody will also indicate on this page the date these Terms were last updated. These Terms are effective upon acceptance by new users. For current users, these Terms are effective as of March 6, 2021 and supersede all previous versions of the Madreambody Terms of Service. If any section or portion of these Terms shall be deemed invalid, void or for any reason unenforceable, then that section of the Terms shall be deemed severable and shall not affect the validity of the remaining condition. Your continued use of the Site after any such changes are posted constitutes your acceptance of the new Terms. If you do not agree to abide by these or any future terms, you may not use or access the Site.
            </p>

            <h2>2.No Medical advice </h2>
            <p>Any text, graphics, images or other material contained, accessed or entered on the Site by you or a health care provider (“Content”) is for educational/informational purposes only and is not a substitute for medical advice, diagnosis or treatment provided by a qualified health care provider. Any communication between you and health care providers on the Site (including any responses by health care providers to questions posted on the Site or through any feature available on the Site) is for general informational purposes only and does not create nor is it intended to create a physician-patient relationship as defined by UK or EU law. Your reliance on any information (including any links to third party websites containing any information about health care providers) or Content provided on the Site, whether or not it is provided by a health care provider, is solely at your own risk. You should always seek the advice of your health care provider for any questions you may have about your own medical condition.</p>

            <h2>3.How and When you may use the site</h2>
            <p>You may use the Site only to post reviews (including photos and videos) based on your own personal experience, to communicate with other community members about questions on personal care, beauty, and wellness and to post questions on the Site. You may only use the Site for personal, non-commercial uses, and you may not use the Site to advertise or promote a product, service, procedure, business, health care provider or another person.  </p>
            <p>You may not use the Site:  </p>

            <ul>
                <li>If you are under 18.  </li>
                <li>In any way or for any purpose that is against any UK, EU or any other applicable laws.  </li>
                <li>In any way or for any purpose that harms or endangers us or our affiliates, resellers, distributors, service providers and/or suppliers (collectively, “Madreambody Parties”), or any customer of Madreambody Party</li>
                <li>To exploit, solicit or harm minors</li>
                <li>To copy any Content from the Site, including, but not limited to, photographs, and post those photographs or other Content on another third-party website.  </li>
                <li>To post or submit any Content that is racist, sexist, inflammatory, defamatory, hateful, harassing, threatening, abusive, profane, obscene, vulgar or sexually explicit, false, misleading, fraudulent, invasive of another’s privacy or publicity rights, infringing of another’s intellectual property rights, contains any trade secrets or other confidential information, or otherwise offensive or objectionable</li>
                <li>To promote, encourage or glorify violence, self-harm, suicide, anorexia, bulimia, or any other practice that is unhealthy or harmful</li>
                <li>To post your name, email address(es) or telephone number(s), URL(s) or any other confidential or personally identifiable information about you or any other person on the Site</li>
                <li>To provide or purport to provide medical advice, prescription, diagnosis, or treatment recommendations</li>
                <li>To register for multiple accounts or use the account of another person without their authorisation</li>
                <li>To impersonate or pretend to be any person other than yourself, including to impersonate or pretend to be a health care provider</li>
                <li>To attempt to solicit business or direct website visitors to a non-Madreambody site for commercial purposes or spamming any Madreambody health care providers, community members or other users</li>
                <li>You agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site</li>
                <li>To post links to sites or files that contain or are viruses, spyware, malware, or other harmful content</li>
                <li>To attempt to interfere with or disrupt the Site or any person’s ability to use the Site</li>
                <li>To create and upload reviews or comments in exchange for monetary compensation or other incentive or </li>
                <li>For any purpose for which the Site is not intended.</li>
            </ul>

            <p>
                We may tell you about certain specific harmful or additional prohibited uses in our Code of Conduct or other notice available through the Site. We have, however, no duty to do so. You agree to abide by any code of Conduct or other notices we provide, which may be changed from time to time. Without limiting the foregoing, you agree to abide by these Terms, the Code of Conduct and any other policies Madreambody has in place. Any use of the Site other than as specifically authorised herein, without the prior written permission of Madreambody, is strictly prohibited.
            </p>
            <p>
                In addition, Madreambody is not obligated to enforce these Terms against another user, health care provider or other third-party on your behalf. If you believe another user, health care provider or other third-party has violated these Terms, then please contact Madreambody by using our contact form. Madreambody reserves the right to investigate the matter and take whatever action Madreambody deems appropriate in its sole discretion.
            </p>

            <h2>4.Your service account: Email consent</h2>

            <p>
                In order to use some of the features on the Site, you are required to register and provide information about yourself to us and create a service account (“Service Account”). It is your responsibility to maintain the confidentiality of your Service Account information, including your Service Account password. In addition, you are responsible for all activities that occur in connection with your Service Account. You must tell us right away about anyone using your Service Account without your consent, or any security breach that relates to the Site or your Service Account. You agree to provide Madreambody with accurate, current and complete information about yourself as may be prompted in any registration forms on the Site and promptly update any such information to keep it accurate, current and complete. Madreambody reserves the right to refuse to provide services to anyone, including terminating your Service Account and/or preventing you from using services, making purchases, or accessing the Site or your Service Account, with or without cause, at any time and in its sole discretion.
            </p>

            <p>
                By using the Site, you consent to receive emails from Madreambody, which may include commercial emails provided such emails are in accordance with the preferences you select in the email and notifications page of the My Profile section of the Site. You may change such preferences by changing your account settings on the email and notifications page of the My Profile section of the Site or via the unsubscribe link in all commercial emails. Please note that as long as you maintain a Service Account, you may not “opt out” of receiving service or account-related emails from Madreambody and all agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.
            </p>

            <h2>5.Materials You Post or Provide; Communications Monitoring</h2>

            <p>You may be able to post or submit reviews, comments, questions, direct messages, photographs, videos (including videos created through a third-party tool) and other materials on or through the Site (collectively, “Submission” or “Submissions”). Your Submissions, (including to the extent applicable personally identifiable information), are made voluntarily and are subject to the Madreambody Code of Conduct and the Madreambody Privacy Policy. You understand Madreambody is not required to treat any of your Submissions as confidential. In addition, you understand and agree that your Submissions (including your username associated with your Submissions) are not confidential and are publicly available for anyone to view on the Site.  </p>

            <p>Madreambody does not claim ownership of the Submission(s) you post or submit on the Site. With the exception of any information you directly submit through a health care provider’s profile or inquiry form, by posting or otherwise providing your Submission on the Site, you are hereby expressly granting MAdreambody a nonexclusive, irrevocable, worldwide, perpetual, royalty free license (including sublicense) to use, copy, distribute, publicly display, publicly perform, modify (including create derivative works) or transmit (including digital transmission of a sound recording) your Submission, in connection with the Site and the Madreamdody business, including without limitation promoting and redistributing part or all of the Site or the Madreambody business, in any media formats and through any media channels now known or hereafter developed. You also grant Madreambody permission to publish and use your name, likeness and voice in connection with your Submission. In addition, by uploading a Submission to the Site, you hereby waive any rights of privacy or publicity. You also grant Madreambody the right to grant any of the above rights to other persons or entities, including for the purpose of promoting the Site or the Madreambody business, without any compensation or obligation to you. You also grant Madreambody the right and authority to send takedown notices (on your behalf) related to your Submission to any individual or entity under the Digital Millennium Copyright Act.  </p>

            <p>Madreambody will not pay you for your Submission. You also understand and agree that Madreambody may, in its sole discretion, refuse, or remove part or all of your Submission from the Site at any time if Madreambody considers the Submission to be illegal, offensive, harassing, infringing, inappropriate or otherwise violates these Terms or the Madreambody Code of Conduct or other policies. For each Submission, you represent and warrant that you have all rights necessary for you to post or submit the Submission and to grant the licenses and permissions in this section and that you are solely responsible for the content of your Submission. Further, you represent and warrant that your Submission does not violate any law.  </p>

            <p>Madreambody reserves the right to review and monitor Submissions but is under no obligation to do so. In addition, Madreambody is not responsible for screening, monitoring or editing Submissions. However, we may monitor your Submissions and may disclose information about you, including the contents of such Submission, if we deem it necessary to: (1) conform to legal requirements or respond to legal process; (2) ensure your compliance with these Terms or other Madreambody guidelines or policies; or (3) protect the rights, property, or interests of Madreambody, its employees, its users, or the public. </p>

            <p>You may also request information directly from a specific health care provider by submitting an inquiry through their profile or inquiry form. You understand these requests, including personally identifiable information, are made voluntarily and are subject to the Madreambody Privacy Policy. There is no guarantee that a health care provider will respond to any request submitted through their profile or inquiry form. In addition, by submitting a request to a health care provider through the Site, you understand that Madreambody may contact you by phone, email or text message about your request. All standard message and data charges will apply to any text messages. </p>

            <h2>6.Materials You Post or Provide; Communications Monitoring </h2>

            <p>Madreambody does not have any responsibility, including editorial control, of any content posted by other Madreambody community members, health care providers or other third party content providers on the Site. The statements or content posted by Madreambody community members, health care providers or other third party content providers on the Site are those of the community members, health care providers or other third party content providers and are not the opinion of Madreambody. Madreambody does not make any representations or warranties about the accuracy or reliability of the content, including the opinions, statements or information posted by the Madreambody community members, health care providers or other third party content providers on the Site. The Madreambody community members, health care providers and other third party content providers are solely responsible for the content posted by them respectively.  </p>

            <p>Madreambody does not endorse or recommend any specific content, procedures, products, opinions, health care providers or any other material or information available on the Site. In addition, the health care providers featured on the Site are not employees or independent contractors of Madreambody and Madreambody does not make any warranties about the qualifications of the health care providers or the costs of the procedures or products featured on the Site.  </p>

            <p>There may be medical related or health related materials posted by Madreambody community members, health care providers or other third parties that contains nudity. If you find these materials to be offensive, then you may want to use the Site in such a way as to limit your viewing of this material. </p>


            <h2>7.Copyright Policy  </h2>

            <p>If you believe that your Submission or any other work has been used in a way that constitutes copyright infringement, or your intellectual property rights have been otherwise violated, please notify Letsmakeover LTD at info@madreambody.com or Copyright Agent, Letsmakeover LTD., 158 Cheapside Birmingham B12 0PN. Provide all of the following in writing:   </p>
            <p>(1) identify the copyrighted work you claim has been infringed or if multiple copyrighted works, then a representative list of such works on the website; (2) identify the material on the website that you claim is infringing and with enough detail so that we may locate the material; (3) provide a statement that you have a good faith belief that the use of the material on the website is not authorised by the copyright owner, its agent, or the law; (4) provide a statement declaring that the notification is accurate, and, under penalty of perjury, that you are the owner of the copyright interest involved or that you are authorised to act on behalf of the exclusive owner; (5) provide information reasonably sufficient to permit Madreambody to contact you, such as an address, telephone number, and email address; and (6) your physical or electronic signature.  </p>
            <p>Upon receipt of notice as described above, Madreambody will take whatever action, in its sole discretion, it deems appropriate, including removal of the alleged infringing material from the website.</p>

            <h2>8.Photograph/VideoPolicy </h2>

            
        </div>
    )
}
