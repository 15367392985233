import React, { useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { node_url, url } from '../../Helpers/helpers';
import { userContext } from '../../Context/UserContext'
import { Country } from 'country-state-city';
import Select from 'react-select'
import { changeLanguage } from 'i18next'
import { useTranslation } from 'react-i18next';
import Autosuggest from 'react-autosuggest';

const RegisterBusiness = () => {
  const { t } = useTranslation()
  const { user, setUser, setLoad } = useContext(userContext);
  const [showOtp, setShowOtp] = React.useState(false)
  const [otp, setOtp] = React.useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [country, setCountry] = useState();
  const [mobile, setMobile] = useState("");
  const [countries, setCountries] = React.useState([])
  const [checked, setChecked] = useState(false)
  const history = useHistory();
  const [cities, setCities] = React.useState([])
  const [city, setCity] = React.useState("")
  const [citySuggestion, setCitySuggestion] = useState([])
  const [refferal, setRefferal] = useState("")

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("gender", gender);
    formData.append("role", 1);
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("country", JSON.stringify(country));
    formData.append("mobile", mobile.split("-")[1]);
    formData.append("city", city)
    formData.append("invited_by", refferal)

    async function submit() {
      setLoad(true)
      const response = await fetch(url + "signup", {
        method: "POST",
        body: formData,
      });

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          toast.success(data.message);
          setShowOtp(true)

        } else if (data.status == 501) {
          toast.error(data.message);
          document.getElementById("login-modal-opener-a").click();
        } else {
          toast.error(data.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    }

    submit().catch((err) => {
      setLoad(false)
      toast.error(err.message);
    });
  }

  function verifyOtp() {
    async function send() {
      setLoad(true)
      const formData = new FormData();
      formData.append("otp", otp)
      formData.append("email", email)

      const response = await fetch(url + 'verify-vendor', {
        method: 'POST',
        body: formData
      })

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          setUser(data.user_data);
          setEmail("");
          setPassword("");
          setGender("");
          setFname("");
          setLname("");
          setCountry("");
          setMobile("");
          setOtp('')
          setShowOtp(false)
          history.push('/dashboard?status=show')
        } else {
          toast.error(data.message)
        }
      } else {
        toast.error("Oops something went wrong!")
      }
    }

    if (otp) {
      send().catch(err => {
        setLoad(false)
        toast.error(err.message)
      })
    } else {
      toast.error("Please enter otp")
    }
  }

  React.useEffect(() => {
    async function fetchData() {
      setLoad(true)
      const response = await fetch(url + 'grabCountries')

      setLoad(false)
      if (response.ok == true) {
        const data = await response.json();

        if (data.status == 200) {
          let lists = data?.list?.map(item => {
            return {
              value: item?.id,
              label: item?.name,
              currency: item?.currency,
              phoneCode: item?.phoneCode
            }
          })

          setCountries(lists)
        } else {
          toast.error(data.message)
        }
      } else {
        toast.error("Oops something went wrong!")
      }
    }

    fetchData().catch(err => {
      setLoad(false)
      toast.error(err.message)
    })

  }, [])

  async function fetchCountriesCities() {
    const response = await fetch(url + 'getCityByState/' + country.value)
    setLoad(false)
    if (response.ok == true) {
      const data = await response.json();

      if (data.status == 200) {
        let lists = data?.list?.map(item => {
          return {
            value: item?.id,
            label: item?.name
          }
        })

        setCities(lists)
      } else {
        toast.error(data.message)
      }

    } else {
      toast.error("Oops something went wrong!")
    }
  }

  useEffect(() => {
    if (country && country.value) {
      fetchCountriesCities().catch(err => {
        toast.error(err.message)
        setLoad(false)
      })
    }
  }, [country])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : cities.filter(lang =>
      lang.label.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // input value for every given suggestion.
  const getSuggestionValue = suggestion => suggestion.label;

  // Use your imagination to render suggestions.
  const renderSuggestion = suggestion => (
    <div>
      {suggestion.label}
    </div>
  );


  const onSuggestionsFetchRequested = ({ value }) => {
    setCitySuggestion(getSuggestions(value))
  };


  const onSuggestionsClearRequested = () => {
    setCitySuggestion([])
  };

  function onChange(event, { newValue }) {
    setCity(newValue)
  }


  const inputProps = {
    placeholder: t('Type city'),
    className: 'form-control',
    value: city,
    onChange: onChange
  };

  return (
    <div className="register-business-page-Y">

      {/* register form section */}
      <div id='register-business-form' className="register-form-div">
        <div className="container position-relative" style={{ zIndex: 99 }}>
          <div className="row">
            <div className="col-md-8 offset-md-2">
              <h2 className="text-center">{t('Let your work speak for itself')}</h2>
              <h1 className="text-center">
                {t('Sign up for free to get in touch with millions of customers worldwide')}
              </h1>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="text"
                    value={fname}
                    onChange={(e) => setFname(e.target.value)}
                    placeholder={t('First Name')}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </div>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="text"
                    value={lname}
                    onChange={(e) => setLname(e.target.value)}
                    placeholder={t('Last Name')}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-pencil" aria-hidden="true"></i>
                </div>
                <div className="position-relative" style={{ background: 'white' }}>
                  <Select className='country-select-dropdown' placeholder="Select Country" options={countries} value={country} onChange={(value) => {
                    setCountry(value)
                    setMobile(`+${value.phoneCode}-`)
                  }} />
                  <i className="fa fa-globe" aria-hidden="true"></i>
                </div>

                <div className="position-relative">
                  {/* <Select options={cities} onChange={setCity} /> */}
                  <Autosuggest
                    suggestions={citySuggestion}
                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    containerProps={{
                      className: 'city-input-autoSuggest'
                    }}
                  />
                  <i className="fa fa-map-marker" aria-hidden="true"></i>

                </div>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="tel"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    placeholder={t('Enter mobile Number without country code')}
                    min={0}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </div>
                <div className="position-relative">
                  <select required
                    className="form-control"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    readOnly={showOtp}
                  >
                    <option value="">{t('Select Gender')}</option>
                    <option value="male">{t('Male')}</option>
                    <option value="female">{t('Female')}</option>
                  </select>
                  <i className="fa fa-user" aria-hidden="true"></i>
                </div>
                <div className="position-relative">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    type="email"
                    placeholder={t('Email Address')}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div className="position-relative">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    type="password"
                    placeholder={t('Password')}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </div>
                <div className="position-relative">
                  <input
                    value={refferal}
                    onChange={(e) => setRefferal(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder={t('Referral Code')}
                    readOnly={showOtp}
                  />
                  <i className="fa fa-user-plus" aria-hidden="true"></i>
                </div>
                {showOtp && <div className="position-relative">
                  <input
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="form-control"
                    type="number"
                    placeholder={t('Enter code sent to your mobile number')}
                  />
                  <i className="fa fa-lock" aria-hidden="true"></i>
                </div>}

                <div className="form-group form-check">
                  <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)} className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label" for="exampleCheck1">{t('By checking this box you are agreeing to our')} &nbsp;<a href="/privacy-policy">{t('Privacy Policy')}</a></label>
                </div>

                {showOtp ? <button disabled={!checked} type="button" onClick={() => verifyOtp()} className="btn">
                  {t('Verify OTP')}
                </button> : <button disabled={!checked} type="submit" className="btn">
                  {t('Register')}
                </button>}
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* details content section */}
      <div className="details-div container">
        {/* first card */}
        <div className="row">
          <div className="col-md-6 all-point-div">
            <p className="mb-4 pb-3">{t('WHO CAN BECOME MADREAMBODY PARTNER ?')} </p>
            <h2>
              {t('whether you own a home or high street Beauty, Cosmetic & Personal Care business such as')}
            </h2>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('MAKE UP - HAIR - NAILS - EYEBROW - SALOON')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('FITNESS COACHING - SPORT COACHING')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('SPA - WAXING - NUTRITION - WELLBEING')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('PHYSIO - THERAPY - ETC...')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-1.jpg"
              alt=""
            />
          </div>
        </div>

        {/* second card */}
        <div className="row flex-row-reverse">
          <div className="col-md-6 all-point-div">
            <div className="d-flex align-items-center icon-img pb-4">
              <img
                className="img-fluid"
                src="./assets/images/RB-img-2_1.png"
                alt=""
              />
              <p className="mb-0 pl-2">
                {t('We connect you with brand new customers online')}
              </p>
            </div>
            <h2>Get Noticed</h2>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('We help build your business profile')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>
                {t('We advertise your business all over our social media platforms')}
              </span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>
                {t('We shoot promotional photo and video content of your business to give more insight view to our customers')}
              </span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>
                {t('We verified customers satisfactions to insure customers has the best service & quality standard')}
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-2.jpeg"
              alt=""
            />
          </div>
        </div>

        {/* third card */}
        <div className="row flex-row-reverse">
          <div className="col-md-6 all-point-div">
            <p className="mb-4 pb-3">{t('DISCOVER MADREAMBODY')}</p>
            <h2>{t('Madreambody is much more than a worldwide booking platform')}</h2>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Manage all clients appointments')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Build your online presence')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Cut back on admin')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('All in one place, on any device')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-3.jpeg"
              alt=""
            />
          </div>
        </div>

        {/* forth card */}
        <div className="row">
          <div className="col-md-6 all-point-div">
            <div className="d-flex align-items-center icon-img pb-4">
              <img
                className="img-fluid"
                src="./assets/images/RB-img-4_1.png"
                alt=""
              />
              <p className="mb-0 pl-2">
                {t('Your home or high street business, bookable worldwide')}
              </p>
            </div>
            <h2 className="mb-4">{t('Get Booked')}</h2>
            <span className="sub-head-span">
              {t('No more missed calls or interrupted treatments. <br /> Receive bookings online 24/7.')}
            </span>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('24/7 online bookings')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Bookings from Facebook')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Real-time availability')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Live chat with your customers')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-4.jpeg"
              alt=""
            />
          </div>
        </div>

        {/* fifth card */}
        <div className="row">
          <div className="col-md-6 all-point-div">
            <div className="d-flex align-items-center icon-img pb-4">
              <img
                className="img-fluid"
                src="./assets/images/RB-img5_1.png"
                alt=""
              />
              <p className="mb-0 pl-2">{t('Smooth experiences, start to finish')}</p>
            </div>
            <h2 className="mb-4">{t('Get Repeats')}</h2>
            <span className="sub-head-span">
              {t('Grow client loyalty')} <br />
              {t('and make them come back.')}
            </span>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Automatic invites to rebook')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Easy email marketing')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Invite customers to follow your social media page')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-5.jpeg"
              alt=""
            />
          </div>
        </div>

        {/* sixth card */}
        <div className="row flex-row-reverse">
          <div className="col-md-6 all-point-div">
            <div className="d-flex align-items-center icon-img pb-1">
              <img
                className="img-fluid"
                src="./assets/images/RB-img-6_1.png"
                alt=""
              />
              <p className="mb-0 pl-2">{t('Digital tools, designed for you')}</p>
            </div>
            <h2 className="mb-3">{t('Get Organised')}</h2>
            <span className="sub-head-span mb-4">
              {t('Take back your time and')} <br />
              {t('grow your business with ease.')}
            </span>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Salon management software')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Instant business insights')}</span>
            </div>
            <div className="d-flex align-items-center inner-points">
              <i className="fa fa-check-circle" aria-hidden="true"></i>
              <span>{t('Easy-to-use point of sale')}</span>
            </div>
          </div>
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/RB-img-6.png"
              alt=""
            />
          </div>
        </div>
      </div>

      {/* Feature to grow business section */}
      <div className="features-div">
        <div className="container position-relative" style={{ zIndex: 99 }}>
          <h2>{t('Some of the features we provide to grow your business')}</h2>
          <img
            className="divider-img"
            src="./assets/images/divider.png"
            alt=""
          />
          <div className="row">
            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/mail.png" alt="" />
              <h3>{t("Smart email marketing")}</h3>
              <p>
                {t('Use our professionally designed email templates to stay in touch clients whenever you like.')}
              </p>
            </div>

            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/online-booking.png" alt="" />
              <h3>{t('24/7 online booking')}</h3>
              <p>
                {t('Let your clients book and rebook their way. Easily manage all appointments on your seamless digital agenda.')}
              </p>
            </div>

            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/sms-reminders.png" alt="" />
              <h3>{t('Unlimited SMS Reminders')}</h3>
              <p>
                {t('Forget about no shows! Send all your clients automatic appointment reminders by SMS and email.')}
              </p>
            </div>

            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/Rebook.png" alt="" />
              <h3>{t('Automatic invites to rebook')}</h3>
              <p>
                {t('Let clients know when it’s time for their next appointment with you so they book back in at the right time, every time.')}
              </p>
            </div>

            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/business-insights.png" alt="" />
              <h3>{t('Instant business insights')}</h3>
              <p>
                {t("Go deeper into your salon performance with tailored performance reports – any way you want them.")}
              </p>
            </div>

            <div className="col-md-4 mb-4 pb-2">
              <img className="icon-img" src="./assets/images/Bookings.png" alt="" />
              <h3>{t('Simple inventory management')}</h3>
              <p>
                {t('Ditch your spreadsheet. Track and order more product stock from one central location.')}
              </p>
            </div>
          </div>

          <img
            className="divider-img"
            src="./assets/images/divider.png"
            alt=""
          />
          <a href="#register-business-form">{t('Partner with us')}</a>
        </div>
      </div>

      {/* upper banner section */}
      {/* <div className="become-partner-banner">
        <div className=" container">
          <p className="mb-4 pb-2">
            How to manage and grow your home or high street business as an
            entrepreneur
          </p>
          <h3>Become our partner to built and grow your business</h3>
          <p>
            Get more customers, build client loyalty and get organised all with
            Madreambody.
          </p>
          <a href="#register-business-form">Become our partner</a>
        </div>
      </div> */}

      {/* testimonials section */}
      <div className="testimonials-div container">
        <p className="mb-4 text-center">{t('TESTIMONIALS')}</p>
        <h2 className="text-center">
          {t('Why do beauty, cosmetic & personal care entrepreneurs choose Madreambody')}
        </h2>
        <p className="text-center">
          {t('Over thousands of health, beauty and cosmetic entrepreneurs worldwide already working beautifully with Madreambody.')}
        </p>
        <div className="row mt-5 pt-4">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src="./assets/images/testimonial-1.jpeg"
              alt=""
            />
          </div>
          <div className="col-md-6 testimonial-desc">
            <h3>
              "{t("I am a young entrepreneur with a drive for supporting talents as a consultant, coach and mentor. I joined Madreambody, thinking it was just an advertisement listing site that would help me get new clients. But it turned out to be so much more than that. I ended up with a whole software system for running my business")}"
            </h3>
            <p>{t('Chrys Mawete, Sport Coach Instructor, Birmingham,UK')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterBusiness;
