import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import { userContext } from 'src/Context/UserContext';
import { node_url, url } from 'src/Helpers/helpers';
import ReactStars from "react-rating-stars-component";
import DowloadApp from 'src/Components/DowloadApp';

function VendorProfile() {
  const { user, setLoad } = useContext(userContext)
  const [slugName, setSlugName] = useState('')
  const [detail, setDetail] = useState({})
  const [posts, setPosts] = useState([])
  const [services, setServices] = useState([])
  const [uniqueCategory, setUniqueCategory] = useState([])
  const [bookingService, setBookingServices] = useState([])
  const [reviewPage, setReviewPage] = useState(1)
  const [reviews, setReviews] = React.useState([]);
  const [gallery, setGallery] = useState([])


  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }


  const id = useQuery().get('id');
  const slug = useQuery().get('slug');


  useEffect(() => {
    if (id) {
      async function FetchSlugName() {
        setLoad(true)
        const response = await fetch(url + 'get-slug-byvendor/' + id, {
          headers: {
            "Authorization": `Bearer ${user?.token}`
          }
        });

        if (response.ok == true) {
          const data = await response.json()

          if (data.status == 200) {
            setLoad(false)
            setSlugName(data?.slug_name)
          } else {
            setLoad(false)
            toast.error(data.message)
          }
        } else {
          setLoad(false)
          toast.error('Internal server error')
        }
      }

      FetchSlugName()
    } else {
      setSlugName(slug)
    }
  }, [])

  async function fetchData() {
    setLoad(true)

    let response = await fetch(url + "fetchListingDetailUser/" + slugName + `?mobile=true`, {
      headers: {
        Authorization: `Bearer ${user?.token}`,
      },
    });

    if (response.ok == true) {
      const data = await response.json();

      if (data.status == 200) {
        console.log('me data hu', data)
        setDetail(data?.listing_detail);
        setPosts(data?.listing_detail?.posts)
        setServices(data?.services);
        setUniqueCategory(data?.categories_unique)
        // let updated_services = []
        // for (let i = 0; i < data?.all_services?.length; i++) {
        //   let item = data?.all_services[i]
        //   console.log(item)
        //   // updated_services.push({
        //   //   value: item.id,
        //   //   label: item.service + " - " + item?.price + " " + data?.listing_detail?.currency,
        //   //   service_name: item.service,
        //   //   price: item?.price,
        //   //   discount: item?.discount,
        //   //   final_amount: item?.final_amount,
        //   //   service_name: item?.service,
        //   //   currency: data?.listing_detail?.currency
        //   // })
        // }

        // setBookingServices(updated_services);
        setGallery(data?.galleryImages);
        setLoad(false)

      } else {
        setLoad(false)
        toast.error(data?.message);
      }
    } else {
      setLoad(false)
      toast.error("Oops something went wrong")
    }
  }

  async function fetchReview() {
    setLoad(true)

    const response = await fetch(url + `fetchReviewsByListingSlugPagination/` + slugName + `?page=${reviewPage}`, {
      headers: {
        "Authorization": `Bearer ${user?.token}`
      }
    });

    if (response.ok == true) {
      const data = await response.json();
      console.log(data)

      if (data.status == 200) {
        if (data?.reviews?.length > 0) {
          setReviews([...reviews, ...data.reviews])
        }
        setLoad(false)

      } else {
        setLoad(false)
        toast.error(data?.message)
      }
    } else {
      setLoad(false)
      toast.error("Oops something went wrong")
    }
  }

  React.useEffect(() => {
    if (slugName) {
      fetchData()
      fetchReview()
    }
  }, [slugName]);

  return (
    <section>
      {/* download app */}
      <DowloadApp />

      <div className='bg-white py-2 pl-3 text-dark'>
        <div className='row'>
          <div className='col-md-4'>
            {detail?.asset ?
              <img src={`${node_url}${detail?.asset}`} style={{ width: '100%', height: 350, aspectRatio: 1 }} />
              : <img src='/assets/images/user.png' style={{ width: 100, height: 50 }} />}
            {detail?.max_discount &&  <div style={{position : 'absolute',top : 10,right : 10,padding : '10px',borderRadius : 100,backgroundColor : 'red',color : 'white',fontWeight : 'bold' }}>
              {detail?.max_discount}
            </div>}
          </div>
          <div className='col-md-8'>
            <div className='ml-3 mt-2'>
              <p className='font-weight-bold mb-0'>{detail?.business_name}</p>
              <p> <i className='fa fa-map mt-1 mr-2'></i> {detail?.location_address}, {detail?.country}, {detail?.city}</p>
              <p className='mb-0 mt-3'>{detail?.biography}</p>

              <span className='vendor-profile-pil'>
                <p className='mb-0'>{detail?.category_name}</p>
              </span>
              <span className='vendor-profile-pil'>
                <p className='mb-0'>{detail?.service_category_name}</p>
              </span>

              <div className="price-tag mt-3">
                <i class="fa fa-tag" aria-hidden="true"></i>
                <span>
                  {detail?.currency} {detail?.maximum_booking} - {detail?.currency} {detail?.minimum_booking}
                </span>
              </div>

              <div className='d-flex mt-4 flex-direction-row'>
                <div>
                  <p className='text-center mb-0 font-weight-bold'>{detail?.post_count}</p>
                  <p className='font-weight-bold'>Posts</p>
                </div>
                <div className='mx-5'>
                  <p className='text-center mb-0 font-weight-bold'>{detail?.follower_count}</p>
                  <p className='font-weight-bold'>Followers</p>
                </div>
                <div>
                  <p className='text-center mb-0 font-weight-bold'>{detail?.reviews}</p>
                  <p className='font-weight-bold'>Reviews</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className='d-flex flex-direction-row align-items-center'>
          {detail?.asset ?
            <img src={`${node_url}${detail?.asset}`} style={{ width: 50, height: 50, borderRadius: 100 }} />
            : <img src='/assets/images/user.png' style={{ width: 50, height: 50, borderRadius: 100 }} />}

          <div className='ml-3 mt-2'>
            <p className='font-weight-bold mb-0'>{detail?.business_name}</p>
            <p> <i className='fa fa-map mt-1 mr-2'></i> {detail?.location_address}, {detail?.country}, {detail?.city}</p>
          </div>
        </div> */}


        <div className='mt-4'>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Posts</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Services</button>
            </li>
            <li class="nav-item" role="presentation">
              <button class="nav-link" id="contact-tab" data-toggle="tab" data-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Reviews</button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <div className='mt-3'>
                {posts.length > 0 ? <>
                  <div className='d-flex flex-wrap'>
                    {posts?.map((item, index) => <div key={index}>
                      <a href='javascript:void(0);' data-toggle="modal" data-target="#exampleModal">
                        <img className='img-fluid' style={{ width: 250, marginTop : 5,marginBottom : 5,marginRight: 8, height: 200, aspectRatio: 1 }} src={`${node_url}${item?.media}`} />
                      </a>
                    </div>)}
                  </div>
                  <div className='d-flex justify-content-center align-items-center mt-4'>
                    <button className='btn btn-primary' data-toggle="modal" data-target="#exampleModal">
                      Load More
                    </button>
                  </div>
                </> : <p className='text-center'>No Record Found</p>}
              </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
              {uniqueCategory.length > 0 && <>
                {uniqueCategory.map((item1, index1) => (
                  <div key={index1}>
                    <p className="text-center font-weight-bold my-3" style={{ borderBottom: '1px solid black', width: 'fit-content', color: 'red' }}>{item1?.category_name}</p>
                    {/* {gallery[index1]?.length > 0 &&  <div>
                      <a>View Gallery</a>
                      <img src={`${node_url}${gallery[index1][0]?.url}`} />
                    </div> } */}
                    <ul>
                      {services[index1]?.length > 0 &&
                        services[index1].map((item, index) => (
                          <li style={{
                            listStyle: 'none',
                            padding: '30px 10px'
                          }} key={index}>
                            <div className='d-flex justify-content-between align-items-center'>
                              <div>
                                <h5>
                                  {item?.service}{" "}
                                  <span className="ml-1">{item?.discount != 0 ? <><s className="text-danger">{item?.price}</s> <span>{item?.final_amount}</span></> : item?.price} {detail?.currency}</span>
                                </h5>
                                <p>{item?.description}</p>
                              </div>
                              <button className='btn btn-primary mr-3' data-toggle="modal" data-target="#exampleModal">
                                Book
                              </button>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                ))}
              </>}
            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
              {reviews?.length > 0 &&
                reviews?.map((item, index) => (
                  <div
                    className="d-flex complete-review-div"
                    key={index}
                  >
                    <div className="reviewer-profile-img">
                      {item?.profile_picture ? (
                        <img
                          src={`${node_url}${item?.profile_picture}`}
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src="/assets/images/user.png"
                          className="img-fluid"
                        />
                      )}
                    </div>
                    <div className="reviewer-review">
                      <h5>{item?.created_by_name}</h5>
                      <span>{item?.created_date_time}</span>
                      <ReactStars
                        edit={false}
                        value={Math.round(item?.rating)}
                      />
                      <p>{item?.comment}</p>
                      <div className="row">

                        {item?.image_1 && <div className="col-md-2"><a target="__blank" href={`${node_url}${item?.image_1}`}><img className="img-fluid" src={`${node_url}${item?.image_1}`} /></a></div>}
                        {item?.image_2 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_2}`}><img className="img-fluid" src={`${node_url}${item?.image_2}`} /></a></div>}
                        {item?.image_3 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_3}`}><img className="img-fluid" src={`${node_url}${item?.image_3}`} /></a></div>}
                        {item?.image_4 && <div className="col-md-2 ml-1"><a target="__blank" href={`${node_url}${item?.image_4}`}><img className="img-fluid" src={`${node_url}${item?.image_4}`} /></a></div>}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

        </div>


      </div>
    </section>
  )
}

export default VendorProfile