import React from 'react'

function DowloadApp() {
    return (
        <div class="modal fade mt-5" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <a href='javascript:void(0);' class="close" data-dismiss="modal" aria-label="Close" style={{position :'absolute',top : 10,right : 20,fontSize : 20}}>
                        <i className='fa fa-close'></i>
                    </a>
                    <div class="modal-body mt-4">
                        <div style={{flexDirection : 'column'}} className='d-flex justify-content-center align-items-center'>
                            <img src='/assets/images/download-mobile.png' />
                            <h2 style={{color : 'red'}} className='mt-4'>Download Our App</h2>
                            <p>Only On Play Store And App Store</p>
                        </div>
                        
                        <div className='d-flex justify-content-center align-items-center mb-4'>
                            <a target="_blank" href='https://apps.apple.com/app/madreambody/id6446048989'>
                                <img style={{width : 150,height : 50,marginRight : 50}} src='/assets/images/download-from-app-store.png' />
                            </a>
                            <a target="_blank" href='https://play.google.com/store/apps/details?id=com.madreambody'>
                                <img style={{width : 150,height : 50}} src='/assets/images/download-from-play-store.png' />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DowloadApp