import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from './store'
import { UserContext } from './Context/UserContext'

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'
React.icons = icons

i18n
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
    supportedLngs : ['en','ar','fr','sp','pr','hi','ben','chi','ger','it','rus'],
    fallbackLng : 'en',
    detection : {
        order : ['cookie','htmlTag','localStorage','path','subdomain'],
        caches : ['cookie'],
    },
    backend : {
        loadPath : '/assets/locales/{{lng}}/translations.json'
    },
    react : {useSuspense : false}
})

ReactDOM.render(
  <Provider store={store}>
    <UserContext>
      <App />
    </UserContext>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
