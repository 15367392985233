import React from "react";

const About = () => {
  return (
    <>
      <div className="page-heading-i">
        <div className="container">
          <div className="page-heading-text">
            <h2 class="text-center mb-0">About Us</h2>
          </div>
        </div>
      </div>
      <div className="about-us-page-i">
        <div className="container">

          {/* about trusted content section */}
          <div className="about-trusted-content">
            <div className="about-content">
              <div className="row">
                <div className="col-md-6">
                  <div className="image-social-div">
                    <div className="social-icons d-flex justify-content-around">
                      <ul className="d-flex">
                        <li>
                          <a href="">
                            <i className="fa fa-twitter" target="_blank"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i className="fa fa-instagram" target="_blank"></i>
                          </a>
                        </li>
                        <li>
                          <a href="">
                            <i
                              className="fa fa-youtube-play"
                              target="_blank"
                            ></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="image">
                      <img
                        src="/assets/images/best-about-us-pages.jpeg"
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    <div className="after-image"></div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-text">
                    <h2 class="">
                      We are a trusted source for people considering to have
                      their dream bodies.
                    </h2>
                    <h3 class="py-3">how we started</h3>
                    <p>
                      Madreambody is a marketplace where people can reliably
                      compare procedures prices, engaged instant communication
                      and connect with our trusted and verified{" "}
                      <strong>Beauty, Cosmetic &amp; Persona</strong>l{" "}
                      <strong>Care Professionals</strong> all over the world as
                      well as learn about the procedures and share experiences.
                      Madreambody provides unbiased information about all
                      procedures (wellbeing, beauty &amp; cosmetic). We showcase
                      the industry leading providers with more than Thousands of
                      certified <strong>Beauty, Cosmetic &amp; Persona</strong>l{" "}
                      <strong>Care Professionals</strong>. We aim to help you
                      make an informed decisions with unparalleled access to our
                      professionals where all questions can be answered.
                    </p>
                    <div className="after-image"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* our promise section */}
          <div className="our-promise-to-you">
            <div className="row align-items-center">
              <div className="col-md-4">
                <h1 className="">Our promise to you</h1>
                <p>
                  Our goal is to have your needs and expectations met to great
                  satisfaction. respecting your rights and ethical values is
                  indispensable for us. Choosing a healthcare or beauty
                  professional has never been easier, Madreambody gives you:
                </p>
                <ul>
                  <li>
                    <span className="font-weight-bold">
                      High Patient Satisfaction:
                    </span>{" "}
                    Madreambody Verified professionals earn and maintain a
                    minimum customer satisfaction rating of 4 out of 5 stars.
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      Good Medical & Wellbeing Standing:
                    </span>{" "}
                    We verify the professional license of each professional
                    every 3 months.
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      Commitment to Transparency:{" "}
                    </span>{" "}
                    Madreambody Verified Personal care & Beauty professionals,
                    promptly answer consumer questions, show unedited before and
                    after photos of their most well-known procedures, and list
                    their active certifications so you can easily find what your
                    professional is certified in.
                  </li>
                  <li>
                    <span className="font-weight-bold">
                      Prompt Responses & Expert Answers:
                    </span>{" "}
                    We verified personal care & beauty Professionals commit to
                    responding to member treatments and procedures request
                    within 48hrs.
                  </li>
                </ul>
              </div>
              <div className="col-md-8">
                <div className="promise-image">
                  <img width="658" height="417"
                    src="/assets/images/our-prmise-image.jpeg"
                    className="img-fluid align-items-center"
                    alt=""
                  />
                </div>
                <div className="promise-after"></div>
              </div>
            </div>
          </div>

          {/* counter section */}
          <div className="counter">
            <div className="content">
              <div className="row">
                <div className="col-md-4">
                  <div className="experience d-flex">
                    <div className="number">
                      <span className="integer">2</span>
                      <span className="icon">+</span>
                    </div>
                    <div className="text">
                      <p>Years of experience</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="specialists d-flex">
                    <div className="number">
                      <span className="integer">500</span>
                      <span className="icon">+</span>
                    </div>
                    <div className="text">
                      <p>Qualified Specialists</p>
                    </div>
                  </div>
                </div>
                
                <div className="col-md-4">
                  <div className="customer-support d-flex">
                    <div className="number">
                      <span className="integer">24</span>
                      <span className="icon">+</span>
                    </div>
                    <div className="text">
                      <p>Customers support</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
